import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import Chart from "react-apexcharts";
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect ,useState } from "react";


const ChartPieRepartition = ({serie,label,couleur,width,height}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data,setData] = useState({})
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setData({serie});
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <div style={{width:width,height:height}}>
      { isLoading ? 
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100%" ,width:"100%"}}>
          <CircularProgress />
        </div>:
    
        <Chart
          options={{
            chart: {
              width: 380,
              type: "pie",
            },
            colors: ["#fc0362","#07922a","#0398fc","#00FFAB","#F7D716","#97AF16","#FF5733", "#33FF57", "#5733FF", "#FF3377", "#33FFEC", "#FFD833", "#3377FF", "#33FFC2", "#FF33A6", "#C233FF", "#FF33E2"],
            labels: label,
            legend: {
              position: "right",
              offsetX: -20, 
              labels: {
                colors: [couleur]
              }
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {width: 200},
                  legend: {position: "bottom"},
                },
              },
            ],
          }}
          series={serie}
          type="pie"
          width="100%"
          height="100%"
          //"110%"
        />
        }
        
    </div>
  );
};

export default ChartPieRepartition;
