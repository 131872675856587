//const environnement = "http://localhost:3000/api"
//const environnement = "http://20.199.126.133/labomag/labomag"
const environnement = "https://newjoule-labomag.ma/labomag/labomag"
//relatif a l'utilisateur
export const signup = `${environnement}/signup`
export const login = `${environnement}/login`
export const updatePassword = `${environnement}/updatePassword`
export const deleteUserByEmail = `${environnement}/deleteUserByEmail`
export const profil = `${environnement}/profil`

//relatif au données
export const getLastData = `${environnement}/getLastData/`
export const getRangeData = `${environnement}/getRangeData/`
export const getRangeDataByTranch = `${environnement}/getRangeDataByTranch/`
export const getDayNightDataByRange = `${environnement}/getDayNightDataByRange/`
export const getEnergyHeurePleineCreusePointe = `${environnement}/getEnergyHeurePleineCreusePointe/`

//relatif a la configuration
export const addTopic = `${environnement}/addTopic`
export const deleteTopicByName = `${environnement}/deleteTopicByName`
export const getAllTopics = `${environnement}/getAllTopics`

export const addTopicMap = `${environnement}/addTopicMap`
export const deleteTopicmapByName = `${environnement}/deleteTopicmapByName`
export const getAllTopicmap = `${environnement}/getAllTopicmap`

export const addSerial = `${environnement}/addSerial`
export const deleteSerialByName = `${environnement}/deleteSerialByName`
export const getAllSerial = `${environnement}/getAllSerial`

export const updateAlert = `${environnement}/updateAlert`
export const getAlert = `${environnement}/getAlert`