import React, { useEffect, useState } from "react";
import { Box, Grid, Tabs, Tab, Container, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import FlashOnIcon from '@mui/icons-material/FlashOn';
import Header from "../../components/Header";
import PuissanceBox from "../../components/PuissanceBox"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import {
  GetLastData,
  GetRangeDataByTranch,
  GetEnergyHeurePleineCreusePointe
} from "../../data/services";
import ChartBarArea from "../../components/ChartBarArea";
import ChartTranche from "../../components/ChartTranche";
import ChartPieRepartition from "../../components/ChartPieRepartition";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const today = new Date();
  const onemonth = new Date();
  onemonth.setDate(today.getDate() - 30);
  const onemonthISO = onemonth.toISOString();

  const [startDate, setStartDate] = useState(onemonthISO);
  const [stopDate, setStopDate] = useState(new Date());

  const [lastData, setlastData] = useState([]);
  const [pushlastData, setpushlastData] = useState([]);
  const [rangeDataByTranch, setrangeDataByTranch] = useState([]);
  const [energyHeurePleineCreusePointe, setenergyHeurePleineCreusePointe] = useState([]);

  const [tranche, setTranch] = React.useState(`day`);
  const handleChangeTranche = (event, newValue) => {
    setTranch(newValue);
  };

  const [unite, setUnit] = React.useState(1);
  const handleChangeunit = (event) => {
    setUnit(event.target.value);
  };

  const [topicMaps, setTopicMaps] = useState(['Compteur_1','Compteur_2','Compteur_3','Compteur_4','Compteur_5','Compteur_6','Compteur_7','Compteur_8','Compteur_9','Compteur_10','Compteur_11','Compteur_12','Compteur_13','Compteur_14','Compteur_15','Compteur_16','Compteur_17']);
  const [serial, setserial] = useState(['bureau_veritas_legrand_id_1', 'bureau_veritas_legrand_id_2', 'bureau_veritas_legrand_id_3', 'bureau_veritas_legrand_id_4', 'bureau_veritas_legrand_id_5', 'bureau_veritas_legrand_id_6', 'bureau_veritas_legrand_id_7', 'bureau_veritas_legrand_id_8', 'bureau_veritas_legrand_id_9', 'bureau_veritas_legrand_id_10', 'bureau_veritas_legrand_id_11', 'bureau_veritas_legrand_id_12', 'bureau_veritas_legrand_id_13', 'bureau_veritas_legrand_id_14', 'bureau_veritas_legrand_id_15', 'bureau_veritas_legrand_id_16', 'bureau_veritas_legrand_id_17']);
  /**
   serial = ['bureau_veritas_legrand_id_1', 'bureau_veritas_legrand_id_2', 'bureau_veritas_legrand_id_3', 'bureau_veritas_legrand_id_4', 'bureau_veritas_legrand_id_5', 'bureau_veritas_legrand_id_6', 'bureau_veritas_legrand_id_7', 'bureau_veritas_legrand_id_8', 'bureau_veritas_legrand_id_9', 'bureau_veritas_legrand_id_10', 'bureau_veritas_legrand_id_11', 'bureau_veritas_legrand_id_12', 'bureau_veritas_legrand_id_13', 'bureau_veritas_legrand_id_14', 'bureau_veritas_legrand_id_15', 'bureau_veritas_legrand_id_16', 'bureau_veritas_legrand_id_17']
   topicmaps = ['salle équipement', 'salle de broyage', 'RDC_1', 'Coffret grenier', 'Physico-chimique N°1', 'Plateaux bureau', 'Physico-chimique N°4', 'Analyse agro N°1', "Ancienne salle d'équipement", 'Escalier RTQR 2eme etage', 'Chargeur véhicule élec', '2ème etage', 'Laboratoire des engrais', 'Réfectoire', 'Clim salle équipement', 'RDC_2', 'Laboratoire sol végétal']
   */



  /* const [topicMaps, setTopicMaps] = useState([]);
  const [serial, setserial] = useState([]);

  useEffect(() => {
    GetTopicMap(setTopicMaps)
    GetSerial(setserial)
    const interval = setInterval(() => {
      GetTopicMap(setTopicMaps)
      GetSerial(setserial)
    }, 600000); //10min 
    return () => {
      clearInterval(interval);
    };
  }, []);

 */

  //la fonction qui fai le cumule---------------------------
  useEffect(() => {
    const cumulativeData = {};
    const pushcumulative = {};
    serial?.forEach(currentSerial => {
      GetLastData(response => {
        Object.keys(response).forEach(key => {
          cumulativeData[key] = (cumulativeData[key] ?? 0) + response[key];
          pushcumulative[key] = pushcumulative[key] ?? [];
          pushcumulative[key].push(response[key]);
        });
        setlastData(cumulativeData);
        setpushlastData(pushcumulative)
        //console.log(cumulativeData)
        //console.log("------------",pushcumulative)
      }, currentSerial);
    });

    const interval = setInterval(() => {
      serial?.forEach(currentSerial => {
        GetLastData(response => {
          Object.keys(response).forEach(key => {
            cumulativeData[key] = (cumulativeData[key] ?? 0) + response[key];
            pushcumulative[key] = pushcumulative[key] ?? [];
            pushcumulative[key].push(response[key]);
          });
          setlastData(cumulativeData);
          setpushlastData(pushcumulative)
          //console.log(cumulativeData)
          //console.log("------------",pushcumulative)
        }, currentSerial);
      });
    }, 600000); //10min 

    return () => {
      clearInterval(interval);
    };
  }, [serial, startDate, stopDate, tranche, unite]);

  /*
  useEffect(() => {

    
    serial?.map((currentSerial)=>{
      GetLastData(setlastData,currentSerial)
    })
     

    const interval = setInterval(() => {
      serial?.map((currentSerial)=>{
        GetLastData(setlastData,currentSerial)
      })

    }, 600000); //10min 
    return () => {
      clearInterval(interval);
    };
  }, [serial,startDate,stopDate,tranche,unite]);

  */

  useEffect(() => {
    GetRangeDataByTranch(setrangeDataByTranch, serial, startDate, stopDate, tranche, unite)
    GetEnergyHeurePleineCreusePointe(setenergyHeurePleineCreusePointe, serial, startDate, stopDate)
    const interval = setInterval(() => {
      GetRangeDataByTranch(setrangeDataByTranch, serial, startDate, stopDate, tranche, unite)
      GetEnergyHeurePleineCreusePointe(setenergyHeurePleineCreusePointe, serial, startDate, stopDate)
    }, 600000); //10min 
    return () => {
      clearInterval(interval);
    };
  }, [serial, startDate, stopDate, tranche, unite]);




  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
        <Box sx={{ mb: 3 }}>
          <Container maxWidth="lg">
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sm={4} md={3}>
                <input className="form-control no-border" type="date" onChange={(date) => setStartDate(date.target?.value)} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <input className="form-control no-border" type="date" onChange={(date) => setStopDate(date.target?.value)} />
              </Grid>
              <Grid item xs={12} sm={5} md={6}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tranche} onChange={handleChangeTranche} variant="scrollable" scrollButtons="auto" textColor={"secondary"} indicatorColor={"secondary"} >
                    <Tab value="day" label="Jour" />
                    <Tab value="week" label="Semaine" />
                    <Tab value="month" label="Mois" />
                    <Box ml="7px" pt="5px">
                      <FormControl variant="standard" sx={{ minWidth: 90 }}>
                        <InputLabel id="demo-simple-select-standard-label">Unité</InputLabel>
                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={unite} onChange={handleChangeunit} label="Age">
                          <MenuItem value={1}>x10<sup>1</sup></MenuItem>
                          <MenuItem value={1000}>x10<sup>-3</sup></MenuItem>
                          <MenuItem value={1000000}>x10<sup>-6</sup></MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Tabs>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>







      {/* 1ère ligne */}
      <Box sx={{ flexGrow: 1, my: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4} sx={{ mx: "auto" }}>
            <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center" borderBottom={`2px solid #fc0362`} >
              {lastData && (
                <PuissanceBox
                  title="Puissance active"
                  pt={`${(lastData?.active_power / unite).toFixed(2)} ${unite === 1 ? "w" : unite === 1000 ? "kw" : "Mw"}`}
                  p1={`P1 : ${(lastData?.active_power_L1 / unite).toFixed(2)} ${unite === 1 ? "w" : unite === 1000 ? "kw" : "Mw"}`}
                  p2={`P2 : ${(lastData?.active_power_L2 / unite).toFixed(2)} ${unite === 1 ? "w" : unite === 1000 ? "kw" : "Mw"}`}
                  p3={`P3 : ${(lastData?.active_power_L3 / unite).toFixed(2)} ${unite === 1 ? "w" : unite === 1000 ? "kw" : "Mw"}`}

                  icon={<FlashOnIcon sx={{ color: "#fc0362", fontSize: "26px" }} />}
                />)}
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={4} sx={{ mx: "auto" }}>
            <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center" borderBottom={`2px solid #07922a`} >
              <PuissanceBox
                title="Puissance reactive"
                pt={`${(lastData?.reactive_power / unite).toFixed(2)} ${unite === 1 ? "var" : unite === 1000 ? "Kvar" : "Mvar"}`}
                p1={`Q1 : ${(lastData?.reactive_power_L1 / unite).toFixed(2)} ${unite === 1 ? "var" : unite === 1000 ? "Kvar" : "Mvar"}`}
                p2={`Q2 : ${(lastData?.reactive_power_L2 / unite).toFixed(2)} ${unite === 1 ? "var" : unite === 1000 ? "Kvar" : "Mvar"}`}
                p3={`Q3 : ${(lastData?.reactive_power_L3 / unite).toFixed(2)} ${unite === 1 ? "var" : unite === 1000 ? "Kvar" : "Mvar"}`}
                icon={<FlashOnIcon sx={{ color: "#07922a", fontSize: "26px" }} />}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={4} sx={{ mx: "auto" }}>
            <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center" borderBottom={`2px solid #0398fc`} >
              <PuissanceBox
                title="Puissance apparente"
                pt={`${(lastData?.apparente_power / unite).toFixed(2)} ${unite === 1 ? "va" : unite === 1000 ? "kva" : "Mva"}`}
                p1={`S1 : ${(lastData?.apparent_power_L1 / unite).toFixed(2)} ${unite === 1 ? "va" : unite === 1000 ? "kva" : "Mva"}`}
                p2={`S2 : ${(lastData?.apparent_power_L2 / unite).toFixed(2)} ${unite === 1 ? "va" : unite === 1000 ? "kva" : "Mva"}`}
                p3={`S3 : ${(lastData?.apparent_power_L3 / unite).toFixed(2)} ${unite === 1 ? "va" : unite === 1000 ? "kva" : "Mva"}`}
                icon={<FlashOnIcon sx={{ color: "#0398fc", fontSize: "26px" }} />}
              // icon={ <FlashOnIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>







      {/* 2ème ligne */}
      <Box sx={{ flexGrow: 1, my: 3 }}>
        <Grid container spacing={3}>
          {/*Gauche */}
          <Grid item xs={12} md={8} sx={{ mx: "auto" }}>
            <Box gridColumn="span 8" gridRow="span 2" backgroundColor={colors.primary[400]}>
              <Box mt="" p="0 30px" display="flex " justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography sx={{ my: 2 }} variant="h4" fontWeight="bold" color={colors.grey[100]}>
                    Energie globale
                  </Typography>
                  <Typography variant="h6" color={colors.greenAccent[500]}>
                    La période est fonction du datepicker , la tranche par {tranche === "day" ? "jour" : tranche === "week" ? "semaine" : "mois"}
                  </Typography>
                </Box>

              </Box>
              <Box >
                {rangeDataByTranch && (
                  <ChartBarArea grid={true} couleur={colors.greenAccent[100]} serie={[
                    {
                      name: unite === 1 ? 'Energie active ( wh )' : unite === 1000 ? 'Energie active ( kwh )' : 'Energie active ( Mwh )',
                      data: rangeDataByTranch.Total_positive_active_energy?.data || []
                    },
                    {
                      name: unite === 1 ? 'Energie reactive ( varh )' : unite === 1000 ? 'Energie reactive ( kvarh )' : 'Energie active ( Mvarh )',
                      data: rangeDataByTranch.Total_positive_reactive_energy?.data || []
                    },
                    {
                      name: unite === 1 ? 'Energie apparente ( vah )' : unite === 1000 ? 'Energie apparente ( kvah )' : 'Energie apparente ( Mvah )',
                      data: rangeDataByTranch.Total_positive_apparent_energy?.data || []
                    }
                  ]} type={"bar"} width={""} height={"250px"} unit={""} categorie={rangeDataByTranch.Total_positive_active_energy?.date || []} />
                )}
              </Box>
            </Box>
          </Grid>
          {/*Droite */}
          <Grid item xs={12} md={4} sx={{ mx: "auto" }}>
            <Box height="330px" gridColumn="span 4" gridRow="span 2" backgroundColor={colors.primary[400]} overflow="auto">
              <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom={`2px solid ${colors.primary[500]}`} colors={colors.grey[100]} p="15px">
                <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
                  Dernière mise a jour
                </Typography>
              </Box>

              <Box>
                {
                  pushlastData.Serial && (
                    pushlastData.Serial.map((serial, index) => (
                      <Box key={index} display="flex" justifyContent="space-between" alignItems="center" borderBottom={`2px solid ${colors.primary[500]}`} p="15px">

                        <Typography color={colors.greenAccent[500]} variant="h5" fontWeight="600">
                          {topicMaps[index]}
                        </Typography>
                        <Typography color={colors.grey[100]}>
                          {pushlastData?.createdAt[index].slice(0, 10)}
                        </Typography>
                        <Box backgroundColor={colors.greenAccent[500]} p="5px 10px" borderRadius="4px">
                          {pushlastData?.createdAt[index].slice(11, 13) + "h:" + pushlastData?.createdAt[index].slice(14, 19)}
                        </Box>
                      </Box>


                    ))
                  )
                }
              </Box>




              {/*mockTransactions.map((transaction, i) => (
                    <Box key={`${transaction.txId}-${i}`} display="flex" justifyContent="space-between" alignItems="center" borderBottom={`4px solid ${colors.primary[500]}`} p="15px">
                      <Box>
                        <Typography color={colors.greenAccent[500]} variant="h5" fontWeight="600">
                          {transaction.txId}
                        </Typography>
                        <Typography color={colors.grey[100]}>
                          {transaction.user}
                        </Typography>
                      </Box>
                      <Box color={colors.grey[100]}>{transaction.date}</Box>
                      <Box backgroundColor={colors.greenAccent[500]} p="5px 10px" borderRadius="4px">
                        ${transaction.cost}
                      </Box>
                    </Box>
                  ))*/}
            </Box>
          </Grid>
        </Grid>
      </Box>









      {/* 3ème ligne */}
      <Box sx={{ flexGrow: 1, my: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} sx={{ mx: "auto" }}>
            <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.primary[400]} p="30px">
              <Typography variant="h5" fontWeight="600">
                Repartition energetique
              </Typography>
              <Box sx={{ height: "200px" }} display="flex" flexDirection="column" alignItems="center" mt="25px">
                {pushlastData && (
                  <ChartPieRepartition
                    serie={pushlastData?.Total_positive_active_energy}
                    label={topicMaps}
                    width={"100%"} height={"100%"}
                  />
                )}

              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mx: "auto" }}>
            <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.primary[400]} >
              <Typography variant="h5" fontWeight="600" sx={{ padding: "30px 30px 0 30px" }} >
                Emission co<sub>2</sub> {unite === 1 ? '( g )' : unite === 1000 ? ' ( kg )' : '( t ) '}
              </Typography>
              <Box sx={{ my: 1 }} ></Box>
              <Box sx={{ py: 3 }} >
                {rangeDataByTranch && (
                  <ChartBarArea grid={true} couleur={colors.greenAccent[100]} serie={[
                    {
                      name: unite === 1 ? 'co2 ( g )' : unite === 1000 ? 'co2 ( kg )' : 'co2 ( t ) ',
                      data: rangeDataByTranch?.Co2 || []
                    },
                  ]} type={"scatter"} width={'97%'} height={"200px"} unit={""} categorie={rangeDataByTranch.Total_positive_active_energy?.date || []} />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mx: "auto" }}>
            <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.primary[400]} padding="23px" >
              <Typography variant="h5" fontWeight="600" sx={{ marginBottom: "40px" }} > {/* sx={{ marginBottom: "15px" }} */}
                Tranche de consommation ( {unite === 1 ? 'wh' : unite === 1000 ? 'kwh' : 'Mwh'} )
              </Typography>

              

              {/*
              energyHeurePleineCreusePointe &&
                Number((energyHeurePleineCreusePointe.heurePointe?.total + energyHeurePleineCreusePointe.heurePleine?.total + energyHeurePleineCreusePointe.heureCreuse?.total) / 1000) < 100 ?
                <Typography variant="h6" className="text-center" fontWeight="bold" sx={{}} >
                  Estimation facture HT : {Number(1.3556 *(energyHeurePleineCreusePointe.heurePointe?.total + energyHeurePleineCreusePointe.heurePleine?.total + energyHeurePleineCreusePointe.heureCreuse?.total) / 1000 ).toLocaleString('fr-FR')} Dh
                ( Tranche 1 )
                </Typography> :

                Number((energyHeurePleineCreusePointe.heurePointe?.total + energyHeurePleineCreusePointe.heurePleine?.total + energyHeurePleineCreusePointe.heureCreuse?.total) / 1000) >= 101 &&
                  Number((energyHeurePleineCreusePointe.heurePointe?.total + energyHeurePleineCreusePointe.heurePleine?.total + energyHeurePleineCreusePointe.heureCreuse?.total) / 1000) < 500
                  ?
                  <Typography variant="h6" className="text-center" fontWeight="bold" sx={{}} >
                    Estimation facture HT : {Number(1.4553 *(energyHeurePleineCreusePointe.heurePointe?.total + energyHeurePleineCreusePointe.heurePleine?.total + energyHeurePleineCreusePointe.heureCreuse?.total) / 1000 ).toLocaleString('fr-FR')} Dh
                    ( Tranche 2 )
                  </Typography> :

                  <Typography variant="h6" className="text-center" fontWeight="bold" sx={{}} >
                    Estimation facture HT : {Number(1.6633*(energyHeurePleineCreusePointe.heurePointe?.total + energyHeurePleineCreusePointe.heurePleine?.total + energyHeurePleineCreusePointe.heureCreuse?.total) / 1000 ).toLocaleString('fr-FR')} Dh
                    ( Tranche 3 )
                  </Typography>
              */
              }

              {/*  <Typography variant="h6" className="text-center" fontWeight="bold" sx={{}} >
                Estimation facture HT : {energyHeurePleineCreusePointe && Number(
                energyHeurePleineCreusePointe.heurePointe?.total/1000 * 1.4157 +
                energyHeurePleineCreusePointe.heurePleine?.total/1000 * 1.0101 +
                energyHeurePleineCreusePointe.heureCreuse?.total/1000 * 0.7398
                ).toLocaleString('fr-FR')} Dh 
              </Typography> */}



              <Box >
                {energyHeurePleineCreusePointe && (
                  <ChartTranche grid={true} couleur={colors.greenAccent[100]} serie={[
                    {
                      name: unite === 1 ? 'H.Pointe ( wh )' : unite === 1000 ? 'H.Pointe ( kwh )' : 'H.Pointe ( Mwh ) ',
                      data: [(energyHeurePleineCreusePointe.heurePointe?.total / unite).toFixed(2)] || []
                    },
                    {
                      name: unite === 1 ? 'H.Pleine ( wh )' : unite === 1000 ? 'H.Pleine ( kwh )' : 'H.Pleine ( Mwh ) ',
                      data: [(energyHeurePleineCreusePointe.heurePleine?.total / unite).toFixed(2)] || []
                    },
                    {
                      name: unite === 1 ? 'H.Creuse ( wh )' : unite === 1000 ? 'H.Creuse ( kwh )' : 'H.Creuse ( Mwh ) ',
                      data: [(energyHeurePleineCreusePointe.heureCreuse?.total / unite).toFixed(2)] || []
                    }
                  ]} width={"100%"} height={"200px"} unit={""} categorie={["H.Pointe", "H.Pleine", "H.Creuse"]} />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>



    </Box>
  );
};

export default Dashboard;
