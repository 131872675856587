import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";

const PuissanceBox = ({ title,pt,p1,p2,p3,icon,color }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="0 30px" >
       <Box>
       <Typography variant="h4" fontWeight="bold" sx={{ color: colors.grey[100],mt:1 }}>
            {title}
        </Typography>
      </Box>
      <Box display="flex">
        <Typography variant="h4"  sx={{ color: colors.grey[100],mt:1 }}>
            <span className="me-3">{icon}</span> 
            <span className="">{pt}</span> 
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" my="10px" >
        <Typography variant="h5" sx={{ color: colors.greenAccent[400] }}>
           {p1}
        </Typography>
        <Typography variant="h5"  sx={{ color: colors.greenAccent[400] }}>
           {p2}
        </Typography>
        <Typography variant="h5"  sx={{ color: colors.greenAccent[400] }}>
           {p3}
        </Typography>
      </Box>
    </Box>
  );
};

export default PuissanceBox;