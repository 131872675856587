import { Outlet, Navigate } from "react-router-dom";
import jwtDecode from 'jwt-decode'

const PrivateRoute = () => {
  let auth ={'token' : false}; 

  if ( window.localStorage.getItem('token')) {
    const token = window.localStorage.getItem('token')
    const { exp } = jwtDecode(token)
    if (exp * 1000 > new Date().getTime()) {
        auth ={'token' : true};
        
    }
}

  return (auth.token ? <Outlet/>:<Navigate to="/login"/> ) 
}

export default PrivateRoute;

