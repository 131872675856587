import React, { useEffect, useState } from "react";
import { Box,Grid,Tabs,Tab, Button,Container, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import ChartJourNuit from "../../components/ChartJourNuit";
import ChartCosPhi from "../../components/ChartCosPhi";
import ChartBarArea from "../../components/ChartBarArea";
import ChartTranche from "../../components/ChartTranche";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { GetDayNightDataByRange, GetEnergyHeurePleineCreusePointe, GetLastData, GetRangeData, GetRangeDataByTranch } from "../../data/services";
import PieChartTwoToneIcon from '@mui/icons-material/PieChartTwoTone';
import PieChartIcon from '@mui/icons-material/PieChart';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import QueryStatsIcon from '@mui/icons-material/QueryStats';


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const COMP_SCHNEIDER = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const today = new Date();
  const onemonth = new Date();
  onemonth.setDate(today.getDate() - 2);
  const onemonthISO = onemonth.toISOString();

  const [startDate, setStartDate] = useState(onemonthISO);
  const [stopDate, setStopDate] = useState(new Date());

  const [tranche, setTranch] = React.useState(`day`);
  const handleChangeTranche = (event, newValue) => {
    setTranch(newValue);
  };

  const [unite, setUnit] = React.useState(1);
  const handleChangeunit = (event) => {
    setUnit(event.target.value);
  };

  const [puissance, setpuissance] = React.useState(`active`);
  const handleChangepuissance = (event, newValue) => {
    setpuissance(newValue);
  };

  const [lastData, setlastData] = useState([]);
  const [rangeData, setrangeData] = useState([]);
  const [rangeDataByTranch, setrangeDataByTranch] = useState([]);
  const [dayNightDataByRange, setdayNightDataByRange] = useState([]);
  const [energyHeurePleineCreusePointe, setenergyHeurePleineCreusePointe] = useState([]);



  useEffect(() => {
    GetLastData(setlastData,props.serial)
    const interval = setInterval(() => {
      GetLastData(setlastData,props.serial)
    }, 300000); //5min 
    return () => {
      clearInterval(interval);
    };
  }, [props.serial]);

  useEffect(() => {
    GetRangeData(setrangeData,props.serial,startDate,stopDate,unite)
    GetRangeDataByTranch(setrangeDataByTranch,[props.serial],startDate,stopDate,tranche,unite)
    GetDayNightDataByRange(setdayNightDataByRange,props.serial,startDate,stopDate)
    GetEnergyHeurePleineCreusePointe(setenergyHeurePleineCreusePointe,[props.serial],startDate,stopDate)

    const interval = setInterval(() => {
      GetRangeData(setrangeData,props.serial,startDate,stopDate,unite)
      GetRangeDataByTranch(setrangeDataByTranch,[props.serial],startDate,stopDate,tranche,unite)
      GetDayNightDataByRange(setdayNightDataByRange,props.serial,startDate,stopDate)
      GetEnergyHeurePleineCreusePointe(setenergyHeurePleineCreusePointe,[props.serial],startDate,stopDate)

    }, 600000); //5min 
    return () => {
      clearInterval(interval);
    };
  }, [props.serial,tranche,startDate,stopDate,unite]);

  return (
    <Box m="20px" overflow="auto">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={props.topicMap} subtitle="Welcome to your dashboard" />

        <Box>
            <Container maxWidth="lg">
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} sm={4} md={3}>
                  <input className="form-control no-border" type="date" onChange={(date) => setStartDate(date.target?.value)} />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <input className="form-control no-border" type="date" onChange={(date) => setStopDate(date.target?.value)} />
                </Grid>
                <Grid item xs={12} sm={5} md={6}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tranche} onChange={handleChangeTranche} variant="scrollable" scrollButtons="auto" textColor={"secondary"}  indicatorColor={"secondary"} >
                        <Tab value="day" label="Jour" />
                        <Tab value="week" label="Semaine" />
                        <Tab value="month" label="Mois"/>
                        <Box ml="7px" pt="5px">
                          <FormControl variant="standard" sx={{  minWidth: 90 }}>
                          <InputLabel id="demo-simple-select-standard-label">Unité</InputLabel>
                          <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={unite} onChange={handleChangeunit} label="Age">
                            <MenuItem value={1}>x10<sup>1</sup></MenuItem>
                            <MenuItem value={1000}>x10<sup>-3</sup></MenuItem>
                            <MenuItem value={1000000}>x10<sup>-6</sup></MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Tabs>
       
                  </Box>
                </Grid>
                
             
            </Grid>
            </Container>
        </Box>
      </Box>
   

















      {/* 1er ligne*/}
      <Box sx={{ flexGrow: 1 ,my:3}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} sx={{ mx: "auto" }}>
            <Box overflow="auto" backgroundColor={colors.primary[400]} sx={{ height: "400px" }}>
                <Box sx={{pt:1,pb:6}} textAlign="center">
                  <Typography variant="h4" sx={{ color:colors.greenAccent[100] }} fontWeight="bold" > Statistiques & valeurs instantanées </Typography>
                </Box>
                <Box  overflow="auto" display="flex" justifyContent="space-between">
                    <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
                      <StatBox title={`${Number(lastData?.voltage_v1).toFixed(2)} v`} subtitle="I1 :" progress={Number(lastData?.voltage_v1/350).toFixed(2)} increase={`${Number(lastData?.current_I1).toFixed(2)} A`}  />
                    </Box>

                    <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
                      <StatBox title={`${Number(lastData?.voltage_v2).toFixed(2)} v`}  subtitle="I2 :" progress={Number(lastData?.voltage_v2/350).toFixed(2)} increase={`${Number(lastData?.current_I2).toFixed(2)} A`}  />
                    </Box>

                    <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
                      <StatBox title={`${Number(lastData?.voltage_v1).toFixed(2)} v`}  subtitle="I3 :" progress={Number(lastData?.voltage_v3/350).toFixed(2)} increase={`${Number(lastData?.current_I3).toFixed(2)} A`}  />
                    </Box>
                </Box>

                {/*satistique colone min max moy*/}
                <Box overflow="auto" sx={{mx:3,pt:1}}  gridColumn="span 4" gridRow="span 2" backgroundColor={colors.primary[400]} >

                  <Typography color={colors.greenAccent[500]} variant="h6" fontWeight="600" px="15px" mt="20px"> {`Energie active ( ${unite===1?'wh':unite===1000?'kwh':'Mwh'} )  par ${tranche==='day'?'jour':tranche==='week'?'semaine':'mois'} durant la période du datepicker`}</Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom={`2px solid ${colors.primary[500]}`} py="3px" px="15px">
                    <Box color={colors.grey[100]}>Min : {rangeDataByTranch ? (rangeDataByTranch?.Total_positive_active_energy?.min/unite).toFixed(1) : 0} </Box>
                    <Box color={colors.grey[100]}>Moy : {rangeDataByTranch ? (rangeDataByTranch?.Total_positive_active_energy?.average/unite).toFixed(1) : 0} </Box>
                    <Box color={colors.grey[100]}>Max : {rangeDataByTranch ? (rangeDataByTranch?.Total_positive_active_energy?.max/unite).toFixed(1) : 0}</Box>
                  </Box>

                  <Typography color={colors.greenAccent[500]} variant="h6" fontWeight="600" px="15px" mt="15px"> {`Energie reactive ( ${unite===1?'varh':unite===1000?'kvarh':'Mvarh'} ) par ${tranche==='day'?'jour':tranche==='week'?'semaine':'mois'} durant la période du datepicker`}</Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom={`2px solid ${colors.primary[500]}`} py="3px" px="15px">
                    <Box color={colors.grey[100]}>Min : {rangeDataByTranch ? (rangeDataByTranch?.Total_positive_reactive_energy?.min/unite).toFixed(1) : 0} </Box>
                    <Box color={colors.grey[100]}>Moy : {rangeDataByTranch ? (rangeDataByTranch?.Total_positive_reactive_energy?.average/unite).toFixed(1) : 0}</Box>
                    <Box color={colors.grey[100]}>Max : {rangeDataByTranch ? (rangeDataByTranch?.Total_positive_reactive_energy?.max/unite).toFixed(1) : 0}</Box>
                  </Box>

                  <Typography color={colors.greenAccent[500]} variant="h6" fontWeight="600" px="15px" mt="15px"> {`Energie apparente( ${unite===1?'vah':unite===1000?'kvah':'Mvah'} ) par ${tranche==='day'?'jour':tranche==='week'?'semaine':'mois'} durant la période du datepicker`}</Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom={`2px solid ${colors.primary[500]}`} py="3px" px="15px">
                    <Box color={colors.grey[100]}>Min : {rangeDataByTranch ? (rangeDataByTranch?.Total_positive_apparent_energy?.min/unite).toFixed(1) : 0} </Box>
                    <Box color={colors.grey[100]}>Moy : {rangeDataByTranch ? (rangeDataByTranch?.Total_positive_apparent_energy?.average/unite).toFixed(1) : 0} </Box>
                    <Box color={colors.grey[100]}>Max : {rangeDataByTranch ? (rangeDataByTranch?.Total_positive_apparent_energy?.max/unite).toFixed(1) : 0}</Box>
                  </Box>

                  <Typography color={colors.greenAccent[500]} variant="h6" fontWeight="600" px="15px" mt="15px"> Courant_L1 ( {unite===1?'A':unite===1000?'kA':'MA'} ) </Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom={`2px solid ${colors.primary[500]}`} py="3px" px="15px">
                    <Box color={colors.grey[100]}>Min : {rangeData ? rangeData?.current_I1?.min?.toFixed(1) : 0} </Box>
                    <Box color={colors.grey[100]}>Moy : {rangeData ? rangeData?.current_I1?.average?.toFixed(1) : 0} </Box>
                    <Box color={colors.grey[100]}>Max : {rangeData ? rangeData?.current_I1?.max?.toFixed(1) : 0}</Box>
                  </Box>

                  <Typography color={colors.greenAccent[500]} variant="h6" fontWeight="600" px="15px" mt="15px"> Courant_L2 ( {unite===1?'A':unite===1000?'kA':'MA'} ) </Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom={`2px solid ${colors.primary[500]}`} py="3px" px="15px">
                    <Box color={colors.grey[100]}>Min : {rangeData ? rangeData?.current_I2?.min?.toFixed(1) : 0} </Box>
                    <Box color={colors.grey[100]}>Moy : {rangeData ? rangeData?.current_I2?.average?.toFixed(1) : 0} </Box>
                    <Box color={colors.grey[100]}>Max : {rangeData ? rangeData?.current_I2?.max?.toFixed(1) : 0}</Box>
                  </Box>

                  <Typography color={colors.greenAccent[500]} variant="h6" fontWeight="600" px="15px" mt="15px"> Courant_L3 ( {unite===1?'A':unite===1000?'kA':'MA'} ) </Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom={`2px solid ${colors.primary[500]}`} py="3px" px="15px">
                    <Box color={colors.grey[100]}>Min : {rangeData ? rangeData?.current_I3?.min?.toFixed(1) : 0} </Box>
                    <Box color={colors.grey[100]}>Moy : {rangeData ? rangeData?.current_I3?.average?.toFixed(1) : 0} </Box>
                    <Box color={colors.grey[100]}>Max : {rangeData ? rangeData?.current_I3?.max?.toFixed(1) : 0}</Box>
                  </Box>

                  <Typography color={colors.greenAccent[500]} variant="h6" fontWeight="600" px="15px" mt="15px"> Tension_L1 ( {unite===1?'v':unite===1000?'kv':'Mv'} ) </Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom={`2px solid ${colors.primary[500]}`} py="3px" px="15px">
                    <Box color={colors.grey[100]}>Min : {rangeData ? rangeData?.voltage_v1?.min?.toFixed(1) : 0} </Box>
                    <Box color={colors.grey[100]}>Moy : {rangeData ? rangeData?.voltage_v1?.average?.toFixed(1) : 0} </Box>
                    <Box color={colors.grey[100]}>Max : {rangeData ? rangeData?.voltage_v1?.max?.toFixed(1) : 0}</Box>
                  </Box>

                  <Typography color={colors.greenAccent[500]} variant="h6" fontWeight="600" px="15px" mt="15px"> Tension_L2 ( {unite===1?'v':unite===1000?'kv':'Mv'} )</Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom={`2px solid ${colors.primary[500]}`} py="3px" px="15px">
                    <Box color={colors.grey[100]}>Min : {rangeData ? rangeData?.voltage_v2?.min?.toFixed(1) : 0} </Box>
                    <Box color={colors.grey[100]}>Moy : {rangeData ? rangeData?.voltage_v2?.average?.toFixed(1) : 0} </Box>
                    <Box color={colors.grey[100]}>Max : {rangeData ? rangeData?.voltage_v2?.max?.toFixed(1) : 0}</Box>
                  </Box>

                  <Typography color={colors.greenAccent[500]} variant="h6" fontWeight="600" px="15px" mt="15px"> Tension_L3 ( {unite===1?'v':unite===1000?'kv':'Mv'} )</Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom={`2px solid ${colors.primary[500]}`} py="3px" px="15px">
                    <Box color={colors.grey[100]}>Min : {rangeData ? rangeData?.voltage_v3?.min?.toFixed(1) : 0} </Box>
                    <Box color={colors.grey[100]}>Moy : {rangeData ? rangeData?.voltage_v3?.average?.toFixed(1) : 0} </Box>
                    <Box color={colors.grey[100]}>Max : {rangeData ? rangeData?.voltage_v3?.max?.toFixed(1) : 0}</Box>
                  </Box>


                  <Typography color={colors.greenAccent[500]} variant="h6" fontWeight="600" px="15px" mt="15px"> Cosφ_L1 </Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom={`2px solid ${colors.primary[500]}`} py="3px" px="15px">
                    <Box color={colors.grey[100]}>Min : {rangeData ? (100*rangeData?.power_factor_L1?.min).toFixed(0) : 0} </Box>
                    <Box color={colors.grey[100]}>Moy : {rangeData ? (100*rangeData?.power_factor_L1?.average).toFixed(0) : 0} </Box>
                    <Box color={colors.grey[100]}>Max : {rangeData ? (100*rangeData?.power_factor_L1?.max).toFixed(0) : 0}</Box>
                  </Box>

                  <Typography color={colors.greenAccent[500]} variant="h6" fontWeight="600" px="15px" mt="15px"> Cosφ_L2 </Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom={`2px solid ${colors.primary[500]}`} py="3px" px="15px">
                    <Box color={colors.grey[100]}>Min : {rangeData ? (100*rangeData?.power_factor_L2?.min).toFixed(0) : 0} </Box>
                    <Box color={colors.grey[100]}>Moy : {rangeData ? (100*rangeData?.power_factor_L2?.average).toFixed(0) : 0} </Box>
                    <Box color={colors.grey[100]}>Max : {rangeData ? (100*rangeData?.power_factor_L2?.max).toFixed(0) : 0}</Box>
                  </Box>

                  <Typography color={colors.greenAccent[500]} variant="h6" fontWeight="600" px="15px" mt="15px"> Cosφ_L3 </Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom={`2px solid ${colors.primary[500]}`} py="3px" px="15px">
                    <Box color={colors.grey[100]}>Min : {rangeData ? (100*rangeData?.power_factor_L3?.min).toFixed(0) : 0} </Box>
                    <Box color={colors.grey[100]}>Moy : {rangeData ? (100*rangeData?.power_factor_L3?.average).toFixed(0) : 0} </Box>
                    <Box color={colors.grey[100]}>Max : {rangeData ? (100*rangeData?.power_factor_L3?.max).toFixed(0) : 0}</Box>
                  </Box>

                  <Typography color={colors.greenAccent[500]} variant="h6" fontWeight="600" px="15px" mt="15px"> Cosφ_L123 </Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom={`2px solid ${colors.primary[500]}`} py="3px" px="15px">
                    <Box color={colors.grey[100]}>Min : {rangeData ? (100*rangeData?.sum_power_factor?.min).toFixed(0) : 0} </Box>
                    <Box color={colors.grey[100]}>Moy : {rangeData ? (100*rangeData?.sum_power_factor?.average).toFixed(0) : 0} </Box>
                    <Box color={colors.grey[100]}>Max : {rangeData ? (100*rangeData?.sum_power_factor?.max).toFixed(0) : 0}</Box>
                  </Box>

                  <Typography color={colors.greenAccent[500]} variant="h6" fontWeight="600" px="15px" mt="15px"> Frequence ( {unite===1?'Hz':unite===1000?'kHz':'MHz'} )</Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom={`2px solid ${colors.primary[500]}`} py="3px" px="15px">
                    <Box color={colors.grey[100]}>Min : {rangeData ? rangeData?.frequency?.min?.toFixed(2) : 0} </Box>
                    <Box color={colors.grey[100]}>Moy : {rangeData ? rangeData?.frequency?.average?.toFixed(2) : 0} </Box>
                    <Box color={colors.grey[100]}>Max : {rangeData ? rangeData?.frequency?.max?.toFixed(2) : 0}</Box>
                  </Box>

                  
                  
                </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} sx={{ mx: "auto" }}>
            <Box backgroundColor={colors.primary[400]} sx={{ height: "100%" , justifyContent: "center" }} >
              <Box  display="flex" alignItems="center">
                <IconButton><PieChartIcon fontSize="large" /> </IconButton>
                <Typography variant="h5" sx={{ ml: 5, color:colors.greenAccent[100] }} fontWeight="bold" > Repartition energetique </Typography>
              </Box>
              <Box  display="flex" alignItems="center">
                {dayNightDataByRange && (
                <ChartJourNuit couleur={colors.greenAccent[100]} 
                    serie={[
                      dayNightDataByRange.day?.total || 0,
                      dayNightDataByRange.night?.total || 0
                    ] }
                    width={"350px"} height={"350px"} />
                )}
                </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} sx={{ mx: "auto" }}>
            <Box backgroundColor={colors.primary[400]} sx={{ height: "100%", justifyContent: "center" }}>
              <Box display="flex" alignItems="center">
                <IconButton><DonutSmallIcon fontSize="large" /> </IconButton>
                <Typography variant="h5" sx={{ ml: 5, color:colors.greenAccent[100] }} fontWeight="bold" > Facteur de puissance </Typography>
              </Box>
              <Box  display="flex" alignItems="center">
                {lastData && (
                <ChartCosPhi couleur={colors.greenAccent[100]} cosPhi1={lastData?.power_factor_L1 || 0} cosPhi2={lastData?.power_factor_L2 || 0} cosPhi3={lastData?.power_factor_L3 || 0} width={"350px"} height={"350px"} />
                )}
              </Box>
              </Box>
          </Grid>
        </Grid>
      </Box>







      {/* 2ème ligne */}
      <Box sx={{ flexGrow: 1 ,my:3}}>
        <Grid container spacing={3}>
          <Grid  item xs={12} md={6} sx={{ mx: "auto" }}>
          <Box  backgroundColor={colors.primary[400]} sx={{ height: "100%" }}>
              <Box sx={{pt:2,pb:6}} textAlign="center">
                <Header title={`Courant ( ${unite===1?'A':unite===1000?'kA':'MA'} )`} subtitle="La période du courant est fonction du datepicker" />
              </Box>
              <Box sx={{pt:2,p:4}}></Box>
              
            {rangeData && (
              <ChartBarArea  grid={false} couleur={colors.greenAccent[100]} serie={[
                  {
                    name: unite===1?'Courant_L1 ( A )':unite===1000?'Courant_L1 ( kA )':'Courant_L1 ( MA )',
                    data: rangeData.current_I1?.data || []
                  },
                  {
                    name: unite===1?'Courant_L2 ( A )':unite===1000?'Courant_L2 ( kA )':'Courant_L2 ( MA )',
                    data: rangeData.current_I2?.data || []
                  },
                  {
                    name: unite===1?'Courant_L3 ( A )':unite===1000?'Courant_L3 ( kA )':'Courant_L3 ( MA )',
                    data: rangeData.current_I3?.data || []
                  }
                ]} type={"area"} width={""} height={"300px"} unit={""} categorie={rangeData.current_I1?.date || []}
              />
            )}
       
          </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ mx: "auto" }}>
            <Box backgroundColor={colors.primary[400]} sx={{ height: "100%" ,justifyContent: "center",mb:1 }} >
            <Box sx={{pt:2}} textAlign="center">
                <Header title="Puissance " subtitle="La période est fonction du datepicker, le choix d'affichage est dans le tab" />
              </Box>
              <Box   >
                <div className="container">
                <Tabs sx={{ width:'50%',mb:2}} value={puissance} onChange={handleChangepuissance} variant="scrollable" scrollButtons="auto" textColor={"secondary"}  indicatorColor={"secondary"} >
                    <Tab value="active" label={`Active( ${unite===1?'w':unite===1000?'kw':'Mw'} )`}  />
                    <Tab value="reactive" label={`Reactive( ${unite===1?'var':unite===1000?'kvar':'Mvar'} )`}   />
                    <Tab value="apparente" label={`Apparente( ${unite===1?'va':unite===1000?'kva':'Mva'} )`}  />
                </Tabs>
                </div>
              </Box>
              <Grid sx={{pb:2 ,pt:2}} container direction="row" justifyContent="space-around" alignItems="center">
                  <Typography variant="h5" fontWeight="bold" color={colors.grey[100]}>Min : { puissance === 'active' ? rangeData.active_power?.min?.toFixed(2)  || 0 : puissance === 'reactive' ? rangeData.reactive_power?.min?.toFixed(2) || 0 :  rangeData.apparente_power?.min?.toFixed(2) || 0}</Typography>
                  <Typography variant="h5" fontWeight="bold" color={colors.grey[100]}>Moy : { puissance === 'active' ? rangeData.active_power?.average?.toFixed(2) || 0 : puissance === 'reactive' ? rangeData.reactive_power?.average?.toFixed(2) || 0 :  rangeData.apparente_power?.average?.toFixed(2) || 0} </Typography>
                  <Typography variant="h5" fontWeight="bold" color={colors.grey[100]}>Max : { puissance === 'active' ? rangeData.active_power?.max?.toFixed(2) || 0 : puissance === 'reactive' ? rangeData.reactive_power?.max?.toFixed(2) || 0 :  rangeData.apparente_power?.max.toFixed(2) || 0}</Typography>
              </Grid>
              {rangeData && (
              <ChartBarArea grid={false} couleur={colors.greenAccent[100]} serie={[
                    {
                        name: puissance === 'active' ? 'Puissance active L1'+(unite===1?'( w )':unite===1000?'( kw )':'( Mw )') : puissance === 'reactive' ? 'Puissance reactive L1'+(unite===1?'( var )':unite===1000?'( kvar )':'( Mvar )') : 'Puissance apparente L1'+(unite===1?'( va )':unite===1000?'( kva )':'( Mva )'),
                        data: puissance === 'active' ? rangeData.active_power_L1?.data || [] : puissance === 'reactive' ? rangeData.reactive_power_L1?.data || [] :  rangeData.apparent_power_L1?.data || []
                    },
                    {
                        name: puissance === 'active' ? 'Puissance active L2'+(unite===1?'( w )':unite===1000?'( kw )':'( Mw )') : puissance === 'reactive' ? 'Puissance reactive L2'+(unite===1?'( var )':unite===1000?'( kvar )':'( Mvar )') : 'Puissance apparente L2'+(unite===1?'( va )':unite===1000?'( kva )':'( Mva )'),
                        data: puissance === 'active' ? rangeData.active_power_L2?.data || [] : puissance === 'reactive' ? rangeData.reactive_power_L2?.data || [] :  rangeData.apparent_power_L2?.data || []
                    },
                    {
                        name: puissance === 'active' ? 'Puissance active L3'+(unite===1?'( w )':unite===1000?'( kw )':'( Mw )') : puissance === 'reactive' ? 'Puissance reactive L3'+(unite===1?'( var )':unite===1000?'( kvar )':'( Mvar )') : 'Puissance apparente L3'+(unite===1?'( va )':unite===1000?'( kva )':'( Mva )'),
                        data: puissance === 'active' ? rangeData.active_power_L3?.data || [] : puissance === 'reactive' ? rangeData.reactive_power_L3?.data || [] :  rangeData.apparent_power_L3?.data || []
                    }
                ]} 
                categorie={ puissance === 'active' ? rangeData.active_power_L1?.date || [] : puissance === 'reactive' ? rangeData.reactive_power_L1?.date || [] :  rangeData.apparent_power_L1?.date || []}
                type={"area"} width={"100%"} height={"300px"} unit={"" } />
              )}
            </Box>
          </Grid>
          
        </Grid>
      </Box>









    {/* 3ème ligne */}
    <Box sx={{ flexGrow: 1 ,my:3}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} sx={{ mx: "auto" }}>
            <Box backgroundColor={colors.primary[400]} sx={{ height: "100%",pb:2 }}>
              <Box sx={{pt:2,}} textAlign="center">
                <Header title="Energie " subtitle={`La période du est fonction du datepicker, la tranche par ${tranche==="day"?'jour':tranche==='week'?'semaine':'mois' }`} />
              </Box>
              {rangeDataByTranch && (
              <ChartBarArea grid={true} couleur={colors.greenAccent[100]} serie={[
                    {
                      name: unite===1?'Energie active ( wh )':unite===1000?'Energie active ( kwh )':'Energie active ( Mwh )' ,
                      data: rangeDataByTranch.Total_positive_active_energy?.data || []
                  },
                  {
                      name:  unite===1?'Energie reactive ( varh )':unite===1000?'Energie reactive ( kvarh )':'Energie active ( Mvarh )',
                      data: rangeDataByTranch.Total_positive_reactive_energy?.data  || []
                  },
                  {
                      name:  unite===1?'Energie apparente ( vah )':unite===1000?'Energie apparente ( kvah )':'Energie apparente ( Mvah )',
                      data: rangeDataByTranch.Total_positive_apparent_energy?.data  || []
                      }
                  ]} type={"bar"} width={""} height={"400px"} unit={""} categorie={rangeDataByTranch.Total_positive_active_energy?.date || [] }/>
                  )}
            </Box>
          </Grid>
       
          
        </Grid>
      </Box>
















     {/* 4ème ligne */}
     <Box sx={{ flexGrow: 1 ,my:3}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} sx={{ mx: "auto" }}>
            <Box backgroundColor={colors.primary[400]} sx={{ height: "100%" ,pb:2}}>
            <Box sx={{pt:2,}} textAlign="center">
                <Header title={`Emission co2 ( ${unite===1?'g':unite===1000?'kg':'t'} )`} subtitle={`La période du est fonction du datepicker, la tranche par ${tranche==="day"?'jour':tranche==='week'?'semaine':'mois' }`} />
              </Box>
            {rangeDataByTranch && (
            <ChartBarArea grid={true} couleur={colors.greenAccent[100]} serie={[
                {
                    name: unite===1?'co<sub>2</sub> ( g )':unite===1000?'co2 ( kg )':'co2 ( t ) ',
                    data: rangeDataByTranch?.Co2 || []
                },
            ]} type={"scatter"} width={''} height={"300px"} unit={""} categorie={rangeDataByTranch.Total_positive_active_energy?.date || []}/>
            )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ mx: "auto" }}>
            <Box backgroundColor={colors.primary[400]} sx={{ height: "100%" , justifyContent: "center" }} >
            <Box sx={{pt:2,}} textAlign="center">
                <Header title={`Tranche de consommation ( ${unite===1?' wh':unite===1000?' kwh':' Mwh'} )`} subtitle={`La période du est fonction du datepicker, synchroniser par les saisons ( l'éte & l'hiver )`} />
              </Box>
              {energyHeurePleineCreusePointe &&(
                <ChartTranche grid={true} couleur={colors.greenAccent[100]} serie={[
                    {
                        name: unite===1?'H.Pointe ( wh )':unite===1000?'H.Pointe ( kwh )':'H.Pointe ( Mwh ) ',
                        data: [(energyHeurePleineCreusePointe.heurePointe?.total/unite).toFixed(2)] || []
                    },
                    {
                        name: unite===1?'H.Pleine ( wh )':unite===1000?'H.Pleine ( kwh )':'H.Pleine ( Mwh ) ',
                        data: [(energyHeurePleineCreusePointe.heurePleine?.total/unite).toFixed(2)] || []
                    },
                    {
                        name: unite===1?'H.Creuse ( wh )':unite===1000?'H.Creuse ( kwh )':'H.Creuse ( Mwh ) ',
                        data: [(energyHeurePleineCreusePointe.heureCreuse?.total/unite).toFixed(2)] || []
                    }
                ]}  width={"100%"} height={"300px"} unit={""} categorie={["H.Pointe","H.Pleine","H.Creuse"]}/>
                )}
            </Box>
          </Grid>
          
        </Grid>
      </Box>



    

    </Box>
  );
};

export default COMP_SCHNEIDER;













/**
 
  <ChartJourNuit energieJour={10} energieNuit={20} width={"300px"} height={"300px"} />
  <ChartCosPhi cosPhi1={0.24} cosPhi2={0.3} cosPhi3={0.3} width={"300px"} height={"300px"} />
  <ChartBarArea serie={[
        {
            name: 'Apparente power L1+',
            data: [10,20,30]
        },
        {
            name: 'Apparente power L2+',
            data: [15,26,35]
        },
        {
            name: 'Apparente power L3+',
            data: [18,22,32]
        }
    ]} type={"scatter"} width={"800px"} height={"300px"} unit={"kva"} categorie={["2023-04","2023-05","2023-06"]}/>


    <ChartTranche serie={[
        {
            name: 'Apparente power L1+',
            data: [10]
        },
        {
            name: 'Apparente power L2+',
            data: [15]
        },
        {
            name: 'Apparente power L3+',
            data: [18]
        }
    ]}  width={"800px"} height={"300px"} unit={"Mw"} categorie={["H.Pointe","H.Pleine","H.Creuse"]}/>


 */