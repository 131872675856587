import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";
import { DataGrid } from '@mui/x-data-grid';



const columns = [
  {
    field: 'id', headerName: 'ID', width: 70, renderCell: (params) => (
      <div style={{ fontSize: '15px' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: 'Compteur', headerName: 'Compteur', width: 130, renderCell: (params) => (
      <div style={{ fontSize: '15px' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: 'Salle', headerName: 'Salle', flex: 1, renderCell: (params) => (
      <div style={{ fontSize: '15px', }}>
        {params.value}
      </div>
    ),
  },
  ,
];

const rows = [
  { id: 1, Compteur: 'Compteur 1', Salle: 'Salle des équipements' },
  { id: 2, Compteur: 'Compteur 2', Salle: 'Microbiologie + Végétal + Phytiatrie + Chambres froides' },
  { id: 3, Compteur: 'Compteur 3', Salle: 'Bureau directeur des opérations + Département techniques + Salle de facturation + Local technique IT + Salle de réunion + Couloir entrée réception' },
  { id: 4, Compteur: 'Compteur 4', Salle: 'Département R&D + Métrologie + Salle de réception des échantillons + Bureau microbilogie + Salle de milieu de culture + Salle oxtamine + WC laboratoires + Bureau TQR' },
  { id: 5, Compteur: 'Compteur 5', Salle: 'Vestiaire + Salle des appareils ( Spectrometrie AA + Flux continue + Salle DBO-DCO )' },
  { id: 6, Compteur: 'Compteur 6', Salle: 'Plateau Bureaux Direction' },
  { id: 7, Compteur: 'Compteur 7', Salle: 'Bureau Responsable + Salle analyse des eaux + RDC 2 (Stock + Stock QUALIMAG + Bureau de maintenance + Bureau médecin du travail + Bibliothèque + Salle de prière + WC RDC)' },
  { id: 8, Compteur: 'Compteur 8', Salle: 'Salle de saisie chimie + salle Agroalimentaire + Salle préparation contaminant + Couloire contaminant' },
  { id: 9, Compteur: 'Compteur 9', Salle: 'Bureau directeur laboratoire + Eclairage salle des équipements + Salle analyse valeur nutritionnelle' },
  { id: 10, Compteur: 'Compteur 1', Salle: 'Les compresseurs + Salle responsable contaminant et responsable R&D + Salle de stockage des échantillons' },
  { id: 11, Compteur: 'Compteur 2', Salle: 'Chargeur voiture électrique' },
  { id: 12, Compteur: 'Compteur 3', Salle: 'Stock QUALIMAG + salle ONSAA' },
  { id: 13, Compteur: 'Compteur 3', Salle: 'Département analyses des engrais' },
  { id: 14, Compteur: 'Compteur 4', Salle: 'Cuisine + Réfectoire' },
  { id: 15, Compteur: 'Compteur 5', Salle: 'QHSSE + IT + Réception' },
  { id: 16, Compteur: 'Compteur 6', Salle: 'Climatiseur salle des équipements' },
  { id: 17, Compteur: 'Compteur 7', Salle: 'Département sol + Agitateur sol & broyeur végétal + Phytiatrie + R&D salle des étuves' },
  { id: 18, Compteur: 'TGBT 1', Salle: 'TGBT 1' },
  { id: 19, Compteur: 'TGBT 2', Salle: 'TGBT 2' },
];


const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="FAQ" subtitle="Page des questions fréquemment posées" />

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Référence sur les compteurs
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" justifyContent="center" alignItems="center">
            <div style={{ height: rows.length * /* hauteur de chaque ligne */ + 60, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[10, 50]}
                checkboxSelection
              />
            </div>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Barre de navigation a gauche
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img alt="profile-user" src={`../../assets/sidebar_.png`} style={{ width: 'auto', height: 'auto' }} />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Le topbar
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
              <img alt="profile-user" src={`../../assets/topbar.png`} style={{ width: '100%', height: 'auto' }} />
            </Box>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Page d'accueil
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Affichage des valeurs instantanées.
            <Box display="flex" justifyContent="center" alignItems="center">
              <img alt="profile-user" src={`../../assets/valinstan.png`} style={{ width: '100%', height: 'auto' }} />
            </Box>
          </Typography>
        </AccordionDetails>

        <AccordionDetails>
          <Typography>
            l'energie globale est impactée par le date picker et la tranche déjà présentée dans le topbar.
            <Box display="flex" justifyContent="center" alignItems="center">
              <img alt="profile-user" src={`../../assets/energie.png`} style={{ width: '100%', height: 'auto' }} />
            </Box>
          </Typography>
        </AccordionDetails>*


        <AccordionDetails>
          En survolant les données, nous pouvons afficher plus de détails tels que son unité, le temps, etc.
          <Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
              <img alt="profile-user" src={`../../assets/repartition.png`} style={{ width: '100%', height: 'auto' }} />
            </Box>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Configuration des paramètres de notification
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Dans la section "Paramètres", nous pouvons configurer les alertes en fixant la limite de dépassement. Par exemple, en définissant une limite de 3000 W pour
            la puissance, cela signifie que lorsque la puissance atteindra cette valeur, l'alerte sera déclenchée.
            <Box display="flex" justifyContent="center" alignItems="center">
              <img alt="profile-user" src={`../../assets/alerte.png`} style={{ width: '100%', height: 'auto' }} />
            </Box>
          </Typography>
        </AccordionDetails>
      </Accordion>

    </Box>
  );
};

export default FAQ;