import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";
import { GetAlert, GetLastData, GetSerial, GetTopicMap } from "../../data/services";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
 
const Alerte = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [alert, setalert] = useState([]);
  const [notification, setnotification] = useState([]);
  const [serial,setserial] = useState([]);
  const [topicMaps,setTopicMaps] = useState([]);

  useEffect(() => {
    GetSerial(setserial)
    GetAlert(setalert)
    GetTopicMap(setTopicMaps)
    const interval = setInterval(() => {
      GetSerial(setserial)
      GetTopicMap(setTopicMaps)
      GetAlert(setalert)
    }, 600000);
    return () => {
        clearInterval(interval)
    }
}, [])


useEffect(() => {
  const pushcumulative = {};
  serial && topicMaps && serial?.forEach((currentSerial,index) => {
    GetLastData((response) => {
      Object.keys(response).forEach(key => {
        if (topicMaps[index] && response[key] && alert[key] && alert[key] > 0 && response[key] >= alert[key] && key !== 'id') {
          pushcumulative[index] = pushcumulative[index] || []; // Assurez-vous que l'index existe dans le tableau
          pushcumulative[index].push(`Alerte ${topicMaps[index]} valeur ${key} a ${response[key]?.toFixed(2) }`);
        }
      });
      setnotification(pushcumulative)
    }, currentSerial);
  });

  const interval = setInterval(() => {
    serial && topicMaps && serial?.forEach((currentSerial,index) => {
      GetLastData((response) => {
        Object.keys(response).forEach(key => {
          if (topicMaps[index] && response[key] && alert[key] && alert[key] > 0 && response[key] >= alert[key] && key !== 'id') {
            pushcumulative[index] = pushcumulative[index] || []; // Assurez-vous que l'index existe dans le tableau
            pushcumulative[index].push(`Alerte ${topicMaps[index]} valeur ${key} a ${response[key]?.toFixed(2)}`);
          } 
        });
        setnotification(pushcumulative)
      }, currentSerial);
    });
  
  }, 300000);
  return () => {
      clearInterval(interval)
  }
}, [serial,alert,topicMaps])



  return (
    <Box m="20px">
      <Header title="Alerte" subtitle="Les alertes" />
      <div> Les alertes sont a unité x10<sup>1</sup> </div>

      <div>
        {notification && Object.keys(notification).map((key,indice) => (
          <div key={indice}>
            {notification[indice]?.map((alert, index) => (
              //<div key={index}>{alert}</div>
              <Box sx={{ width: '100%' }} mt="20px">
                <Alert spacing={2} key={index} severity="error">{alert}</Alert>
              </Box>
            ))}
          </div>
        ))}
      </div>

    </Box>
  );
};

export default Alerte;