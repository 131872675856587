import { Grid,Box, Button, TextField,Typography,useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import axios from 'axios';
import Header from "../../components/Header";
import { DeleteSerial, DeleteTopic, DeleteTopicmap, DeleteUser, GetSerial, GetTopic, GetTopicMap, Profil,GetAlert } from "../../data/services";
import EditIcon from '@mui/icons-material/Edit';
import { tokens } from "../../theme";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';
import { addSerial, addTopic, addTopicMap, signup, updatePassword,updateAlert } from "../../routes";
import Header_ from "../../data/Header";

const Parametre= () => {
  const [profil, setprofil] = useState([])
  const [checked, setChecked] = React.useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  

  const [topic,setTopic] = useState([]);
  const [topicMaps,setTopicMaps] = useState([]);
  const [serial,setserial] = useState([]);

  const [credentials, setCredentials] = useState({ email: "", oldPassword: "", newPassword: "", confirmPass: "" })
  const [credentialsSignup, setCredentialsSignup] = useState({ userName: "", email: "", password: ""})
  const [credentialTopicTopicmapSerial, setCredentialTopicTopicmapSerial] = useState({ topic: "", topicmap: "", serial: ""})

  const [deletUser,setDeletUser] = useState([])
  const [emailToDelete, setEmailToDelete] = useState('');

  const [voirpass, setvoirpass] = useState(false)
  const [erreur, seterreur] = useState("")
  const [erreurSignup, seterreurSignup] = useState("")
  const [erreurTopic, seterreurTopic] = useState("")
  const [erreurTopicmap, seterreurTopicmap] = useState("")
  const [erreurSerial, seterreurSerial] = useState("")

  
  const [editing, setEditing] = useState(false);

  const handleEditClick = () => {
    setEditing(!editing);
  };

  const [alert, setalert] = useState({
    voltage_v1:'',
    voltage_v2:'',
    voltage_v3:'',
    current_I1:'',
    current_I2:'',
    current_I3:'',
    active_power:'',
    reactive_power:'',
    apparente_power:'',
    active_power_L1:'',
    active_power_L2:'',
    active_power_L3:'',
    reactive_power_L1:'',
    reactive_power_L2:'',
    reactive_power_L3:'',
    apparent_power_L1:'',
    apparent_power_L2:'',
    apparent_power_L3:'',
    frequency:'',
    power_factor_L1:'',
    power_factor_L2:'',
    power_factor_L3:'',
    Total_positive_active_energy:'',
    Total_positive_reactive_energy:'',
    Total_positive_apparent_energy:'',
  });
  const handleChange = (e) => {
    setalert({ ...alert, [e.target.name]: e.target.value });
  };
  const handleSubmitAlert = async (event) => {
    event.preventDefault();
    try {
        delete alert.id
        await axios.post(updateAlert,alert,Header_(),
        ).then(response => {
            console.log(response?.data)
            console.log(alert)
            //if(response?.data===500){seterreurSignup("L'utilisateur existe déja")}
            //else {seterreurSignup("Toutes les alertes ont été mises à jour avec succès.")}            
        })
            
    } catch (error) {
        //if(error.data===200){seterreurSignup("error serveur")}
        console.log(error)
    }
  };


//handles chnagement
  const handlchange = ({ currentTarget }) => {
    console.log(currentTarget)
    const { value, name } = currentTarget
    setCredentials({ ...credentials, [name]: value })
}

const handlchangeSignUp = ({ currentTarget }) => {
    console.log(currentTarget)
    const { value, name } = currentTarget
    setCredentialsSignup({ ...credentialsSignup, [name]: value })
}

const handlchangeTopicTopicmapSerial = ({ currentTarget }) => {
    console.log(currentTarget)
    const { value, name } = currentTarget
    setCredentialTopicTopicmapSerial({ ...credentialTopicTopicmapSerial, [name]: value })
}


//submites
const handlsubmite = async (event) => {
    event.preventDefault();
    try {
        await axios.put(updatePassword, 
            {
                email:credentials.email,
                oldPassword:credentials.oldPassword,
                newPassword:credentials.newPassword
            },Header_(),
        ).then(response => {
            seterreur(response?.data)
            //console.log(response.data)
        })
            
    } catch (error) {
        seterreur("User or "+error?.response?.data)
        console.log(error)
    }
}

const handlsubmiteSignUp = async (event) => {
    event.preventDefault();
    try {
        await axios.post(signup, 
            {
                userName:credentialsSignup.userName,
                email:credentialsSignup.email,
                password:credentialsSignup.password
            },Header_(),
        ).then(response => {
            if(response?.data===409){seterreurSignup("L'utilisateur existe déja")}
            else {seterreurSignup("L'utilisateur a été créé avec succès")}            
        })
            
    } catch (error) {
        if(error.data===409){seterreurSignup("Les informations fournies sont incorrectes")}
        if(error.data===200){seterreurSignup("error serveur")}
        console.log(error)
    }
}

const handlTopic = async (event) => {
    event.preventDefault();
    try {
        await axios.post(addTopic,{name:credentialTopicTopicmapSerial.topic },Header_(),
        ).then(response => {
            //console.log(response?.data)
            seterreurTopic("Topic mis à jour avec succès.")})      
    } catch (error) {
        //console.log(response?.data)
        seterreurTopic(error.response.data)
    }
}

const handlTopicmap = async (event) => {
    event.preventDefault();
    try {
        await axios.post(addTopicMap,{name:credentialTopicTopicmapSerial.topicmap },Header_(),
        ).then(response => {
            //console.log(response)
            seterreurTopicmap("Topicmap mis à jour avec succès.")})      
    } catch (error) {
        //console.log(response?.data)
        seterreurTopicmap(error.response.data)
    }
}

const handlSerial = async (event) => {
    event.preventDefault();
    try {
        await axios.post(addSerial,{name:credentialTopicTopicmapSerial.serial },Header_(),
        ).then(response => {
            //console.log(response?.data)
            seterreurSerial("Serial mis à jour avec succès.")
        })      
    } catch (error) {
        //console.log(response?.data)
        seterreurSerial(error.response.data)
    }
}


//suppression
const handleEmailToDeleteChange = (event) => {
    setEmailToDelete(event.target.value);
  }
const DeleteUsers = () => {
    DeleteUser(setDeletUser, emailToDelete);
  };

const [deleteTopic,setDeleteTopic] = useState([])
const [topicTodelete, setTopicTodelete] = useState('');
const handleTopic = (event) => {
    setTopicTodelete(event.target.value);
  }
const DeleteTopics = () => {
    DeleteTopic(setDeleteTopic, topicTodelete);
  };
  
const [deleteTopicmap,setDeleteTopicmap] = useState([])
const [topicmapTodelete, setTopicmapTodelete] = useState('');
const handleTopicmap = (event) => {
    setTopicmapTodelete(event.target.value);
  }
const DeleteTopicmaps = () => {
    DeleteTopicmap(setDeleteTopicmap, topicmapTodelete);
  };

const [deleteSerial,setDeleteSerial] = useState([])
const [serialTodelete, setSerialTodelete] = useState('');
const handleSerial = (event) => {
    setSerialTodelete(event.target.value);
  }
const DeleteSerials = () => {
    DeleteSerial(setDeleteSerial, serialTodelete);
  };

  useEffect(() => {
    Profil(setprofil)
    GetTopic(setTopic)
    GetTopicMap(setTopicMaps)
    GetSerial(setserial)
    GetAlert(setalert)
    const interval = setInterval(() => {
        Profil(setprofil)
        GetTopic(setTopic)
        GetTopicMap(setTopicMaps)
        GetSerial(setserial)
        GetAlert(setalert)
    }, 600000);
    return () => {
        clearInterval(interval)
    }
}, [])

useEffect(() => {
    seterreur("")
    seterreurSignup("")
    setDeletUser("")
    seterreurTopic("")
    seterreurTopicmap("")
    seterreurSerial("")
    setDeleteTopic("")
    setDeleteTopicmap("")
    setDeleteSerial("")
    const interval = setInterval(() => {
        seterreur("")
        seterreurSignup("")
        setDeletUser("")
        seterreurTopic("")
        seterreurTopicmap("")
        seterreurSerial("")
        setDeleteTopic("")
        setDeleteTopicmap("")
        setDeleteSerial("")
    }, 5000);
    return () => {
        clearInterval(interval)
    }
}, [])

  return (
    <div className="container">
      <div class='col  mt-2 mb-2 '  >
      <Header title="Reset password" subtitle="Changer le mot de passe de l'utilisateur" />
        <form onSubmit={ credentials.confirmPass===credentials.newPassword ? handlsubmite :()=>seterreur("New password different du confirm Password")} >
            <div className='mb-4 mt-4'>
                <TextField 
                    name="email" 
                    id="email" 
                    label="User" 
                    variant="standard"  
                    onChange={handlchange} 
                    fullWidth
                    />
            </div>
            <div className='mt-2 '>
                <span className='d-flex align-items-center  justify-content-center'>
                    <TextField name="oldPassword"
                        id="exampleInputPassword1"
                        label="Old Password"
                        variant={"standard"}
                        type={voirpass ? 'text' : 'password'}
                        autoComplete="current-oldPassword"
                        onChange={handlchange}
                        fullWidth
                    />
                    <span onClick={() => { setvoirpass(!voirpass) }} >{voirpass ? <VisibilityIcon /> : <VisibilityOffIcon />} </span>
                </span>

            </div>
            <div className='mt-2'>
                <span className='d-flex align-items-center  justify-content-center'>
                    <TextField name="newPassword"
                        id="exampleInputPassword11"
                        label="New Password"
                        variant={"standard"}
                        type={voirpass ? 'text' : 'password'}
                        autoComplete="current-password"
                        onChange={handlchange}
                        fullWidth
                    />

                </span>

            </div>

            <div className='mt-2'>
                <span className='d-flex align-items-center  justify-content-center'>
                    <TextField name="confirmPass"
                        id="exampleInputPassword111"
                        label="Confirm Password"
                        variant={"standard"}
                        type={voirpass ? 'text' : 'password'}
                        autoComplete="current-password"
                        onChange={handlchange}
                        fullWidth
                    />

                </span>

            </div>
            <div className='container text-center mt-4 text-danger fs-6'>{
                erreur === "Mot de passe mis à jour avec succès." ?
                    <Alert severity="success">{erreur}</Alert> :
                    erreur === "" ? "" :
                    <Alert severity="error">{erreur}</Alert>}
            </div>
            
            
            <Grid container justifyContent="flex-end">
                <Button variant="contained" color='info' endIcon={<EditIcon />} type="submit"   className='mt-3 ' style={{width:"110px"}} >
                Modifier
                </Button>
            </Grid>
           

        </form>

        {/*-----------------Alarme --------------------------*/}
        <form onSubmit={handleSubmitAlert}>
            <Header title="Alert" subtitle="Configuration des paramètres de notification " />
            <Grid container justifyContent="flex-end">
                <Button variant={!editing? "outlined":"contained"}  color='info' className='mt-3 '  onClick={handleEditClick}  endIcon={<EditIcon />}>
                    
                </Button>
            </Grid>

            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ display: 'flex', flexWrap: 'wrap' }}>
            {[ 'voltage_v1',
                'voltage_v2',
                'voltage_v3',
                'current_I1',
                'current_I2',
                'current_I3',
                'active_power',
                'reactive_power',
                'apparente_power',
                'active_power_L1',
                'active_power_L2',
                'active_power_L3',
                'reactive_power_L1',
                'reactive_power_L2',
                'reactive_power_L3',
                'apparent_power_L1',
                'apparent_power_L2',
                'apparent_power_L3',
                'power_factor_L1',
                'power_factor_L2',
                'power_factor_L3',
                'Total_positive_active_energy',
                'Total_positive_reactive_energy',
                'Total_positive_apparent_energy',
                'frequency',
            ].map((fieldName) => (
                <div key={fieldName} style={{ flexBasis: 'calc(33.33% - 16px)', marginBottom: '20px' }}>
                <label>{fieldName}:</label>
                <TextField
                    type="text"
                    name={fieldName}
                    value={alert[fieldName]}
                    onChange={handleChange}
                    id={fieldName}
                    variant="standard"
                    fullWidth
                    disabled={!editing} 
                />
                </div>
            ))}
            </Grid>

            <Grid container justifyContent="flex-end">
                <Button disabled={!editing}  variant="contained" color='info' endIcon={<AddIcon/>} type="submit"   className='mt-3 ' style={{width:"120px"}} >
                    Appliquer
                </Button>
            </Grid>

        </form>



        {/*-----------------Partie reservé a newjoule --------------------------*/}
        { profil.email==="newjoule@newjoule"? <div>
            {/*Ajouter utilisateur  */}
            <form className="mt-5" onSubmit={
                credentialsSignup.email && credentialsSignup.email.trim() &&
                credentialsSignup.userName  && credentialsSignup.userName.trim() &&
                credentialsSignup.password  && credentialsSignup.password.trim() ?
                handlsubmiteSignUp:()=>seterreurSignup("Tous les champs sont requis") }>
            <Header title="Nouveau user" subtitle="Creation d'un nouvel utilisateur" />
                <div className='mb-4 mt-4'>
                    <TextField 
                        name="userName" 
                        id="userName" 
                        label="userName" 
                        variant="standard"  
                        onChange={handlchangeSignUp} 
                        fullWidth
                        />
                </div>
                <div className='mb-4 mt-4'>
                    <TextField 
                        name="email" 
                        id="useremail" 
                        label="email" 
                        variant="standard"  
                        onChange={handlchangeSignUp} 
                        fullWidth
                        />
                </div>
                <div className='mb-4 mt-4'>
                    <TextField 
                        name="password" 
                        id="password" 
                        label="password" 
                        variant="standard"  
                        onChange={handlchangeSignUp} 
                        fullWidth
                        />
                </div>
                <div className='container text-center mt-4 text-danger fs-6'>{
                    erreurSignup === "L'utilisateur a été créé avec succès" ?
                        <Alert severity="success">{erreurSignup}</Alert> :
                        erreurSignup === "" ? "" :
                        <Alert severity="error">{erreurSignup}</Alert>}
                </div>
                <Grid container justifyContent="flex-end">
                    <Button variant="contained" color='info' endIcon={<AddIcon/>} type="submit"   className='mt-3 ' style={{width:"120px"}} >
                    Crée
                    </Button>
                </Grid>
            </form>


            {/*Suprimer utilisateur */}
            <Header title="Supprimer un user" subtitle="Suprimer un utilisateur par son email" />
            <div className='mb-4 mt-4'>
                <TextField 
                    name="emailToDelete" 
                    id="emailToDelete" 
                    label="Email to delete" 
                    variant="standard"  
                    onChange={handleEmailToDeleteChange} 
                    fullWidth
                />
            </div>
            <div className='container text-center mt-4 text-danger fs-6'>{
                deletUser === "L'utilisateur a été supprimé avec succès." ?
                    <Alert severity="success">{deletUser}</Alert> :
                deletUser === "" ? "" : <Alert severity="error">{deletUser}</Alert>}
            </div>
            <Grid container justifyContent="flex-end">
                <Button variant="contained" color='info' endIcon={<DeleteIcon/>} onClick={DeleteUsers} className='mt-3' style={{width:"120px"}}>
                    Delete
                </Button>
            </Grid>

            {/*Afichage des topic topicmap serial ---------------------*/}
            <Box sx={{mt:4,pb:5}}>
                <Header title="Informations" subtitle="Tous les topics - topicmaps - serials des compteurs" />

                <Box  display="flex" justifyContent="space-between" alignItems="center">
                    <Box className="fw-bold fs-6">Topicmap( side bar ) </Box>
                    <Box className="fw-bold fs-6">Serial</Box>
                    <Box className="fw-bold fs-6">Topic ( backend ) </Box>

                </Box>
                {
                serial &&(
                    serial.map((serial, index) => (
                    <Box key={index}  display="flex" justifyContent="space-between" alignItems="center" borderBottom={`2px solid ${colors.primary[500]}`} p="15px">

                        <Typography color={colors.greenAccent[500]} variant="h5" fontWeight="600">
                        {topicMaps[index]}
                        </Typography>
                    
                        <Typography color={colors.greenAccent[500]} variant="h5" fontWeight="600">
                        {serial}
                        </Typography>

                        <Typography color={colors.greenAccent[500]} variant="h5" fontWeight="600">
                        {topic[index]}
                        </Typography>
                    </Box>


                    ))
                )
                }
            </Box>

            {/*Ajouter un topic */}
            <form className="mt-5" onSubmit={credentialTopicTopicmapSerial.topic &&
                credentialTopicTopicmapSerial.topic.trim()? handlTopic:()=>seterreurTopic("Le champ topic est requis ! ")} >
                <Header  title="Add Topic - Topicmap - Serial" subtitle="Ajouter un vouveau topic" />
                <div className=''>
                    <TextField 
                        name="topic" 
                        id="topic" 
                        label="Add topic" 
                        variant="standard"  
                        onChange={handlchangeTopicTopicmapSerial} 
                        fullWidth
                    />
                </div>
                <div className='container text-center mt-1 text-danger fs-6'>{
                    erreurTopic === "Topic mis à jour avec succès." ?
                        <Alert severity="success">{erreurTopic}</Alert> :
                        erreurTopic === "" ? "" :
                        <Alert severity="error">{erreurTopic}</Alert>}
                </div>
            
                <Grid container justifyContent="flex-end">
                    <Button variant="contained" color='info' endIcon={<AddIcon/>} type="submit" style={{width:"120px"}}>
                        Add
                    </Button>
                </Grid>
            </form>

            {/*Ajouter un topicmap */}
            <form className="mt-5" onSubmit={credentialTopicTopicmapSerial.topicmap &&
                credentialTopicTopicmapSerial.topicmap.trim()? handlTopicmap:()=>seterreurTopicmap("Le champ topicmap est requis !")} >
                <Header  subtitle="Ajouter un vouveau topicMap" />
                <div >
                    <TextField 
                        name="topicmap" 
                        id="topicmap" 
                        label="Add topicMap" 
                        variant="standard"  
                        onChange={handlchangeTopicTopicmapSerial} 
                        fullWidth
                    />
                </div>
                <div className='container text-center mt-1 text-danger fs-6'>{
                    erreurTopicmap === "Topicmap mis à jour avec succès." ?
                        <Alert severity="success">{erreurTopicmap}</Alert> :
                        erreurTopicmap === "" ? "" :
                        <Alert severity="error">{erreurTopicmap}</Alert>}
                </div>
                <Grid container justifyContent="flex-end">
                    <Button variant="contained" color='info' endIcon={<AddIcon/>} type="submit"  style={{width:"120px"}}>
                        Add
                    </Button>
                </Grid>
            </form>

            {/*Ajouter un serial */}
            <form className="mt-5" onSubmit={credentialTopicTopicmapSerial.serial &&
                credentialTopicTopicmapSerial.serial.trim()? handlSerial:()=>seterreurSerial("Le champ serial est requis")} >
                <Header  subtitle="Ajouter une serial" />
                <div >
                    <TextField 
                        name="serial" 
                        id="serial" 
                        label="Add serial" 
                        variant="standard"  
                        onChange={handlchangeTopicTopicmapSerial} 
                        fullWidth
                    />
                </div>
                <div className='container text-center mt-1 text-danger fs-6'>{
                    erreurSerial === "Serial mis à jour avec succès." ?
                        <Alert severity="success">{erreurSerial}</Alert> :
                        erreurSerial === "" ? "" :
                        <Alert severity="error">{erreurSerial}</Alert>}
                </div>
                <Grid container justifyContent="flex-end" className="mb-5">
                    <Button variant="contained" color='info' endIcon={<AddIcon/>} type="submit"  style={{width:"120px"}}>
                        Add
                    </Button>
                </Grid>
            </form>



            {/*Suprimer un topic */}
            <Header title="Supprimer Topic - Topicmap - Serial" subtitle="Suprimer un topic par son identifiant" />
            <div >
                <TextField 
                    name="topicToDelete" 
                    id="topicToDelete" 
                    label="Topic to delete" 
                    variant="standard"  
                    onChange={handleTopic} 
                    fullWidth
                />
            </div>
            <div className='container text-center mt-1 text-danger fs-6'>{
                deleteTopic === "Le topic a été supprimé avec succès." ?
                    <Alert severity="success">{deleteTopic}</Alert> :
                deleteTopic === "" ? "" : <Alert severity="error">{deleteTopic}</Alert>}
            </div>
            <Grid container justifyContent="flex-end">
                <Button variant="contained" color='info' endIcon={<DeleteIcon/>} onClick={DeleteTopics} style={{width:"120px"}}>
                    Delete
                </Button>
            </Grid>

            {/*Suprimer un topicmap */}
            <Header  subtitle="Suprimer un topicmap par son identifiant" />
            <div >
                <TextField 
                    name="topicmapToDelete" 
                    id="topicmapToDelete" 
                    label="Topicmap to delete" 
                    variant="standard"  
                    onChange={handleTopicmap} 
                    fullWidth
                />
            </div>
            <div className='container text-center mt-1 text-danger fs-6'>{
                deleteTopicmap === "Le topicmap a été supprimé avec succès." ?
                    <Alert severity="success">{deleteTopicmap}</Alert> :
                deleteTopicmap === "" ? "" : <Alert severity="error">{deleteTopicmap}</Alert>}
            </div>
            <Grid container justifyContent="flex-end">
                <Button variant="contained" color='info' endIcon={<DeleteIcon/>} onClick={DeleteTopicmaps} style={{width:"120px"}}>
                    Delete
                </Button>
            </Grid>

            {/*Suprimer un serial */}
            <Header  subtitle="Suprimer un serial par son identifiant" />
            <div >
                <TextField 
                    name="serialToDelete" 
                    id="serialToDelete" 
                    label="serial to delete" 
                    variant="standard"  
                    onChange={handleSerial} 
                    fullWidth
                />
            </div>
            <div className='container text-center mt-1 text-danger fs-6'>{
                deleteSerial === "La serie a été supprimé avec succès." ?
                    <Alert severity="success">{deleteSerial}</Alert> :
                deleteSerial === "" ? "" : <Alert severity="error">{deleteSerial}</Alert>}
            </div>
            <Grid container justifyContent="flex-end" className="mb-5">
                <Button variant="contained" color='info' endIcon={<DeleteIcon/>} onClick={DeleteSerials} style={{width:"120px"}}>
                    Delete
                </Button>
            </Grid>

        </div> : ""}


      </div>


    </div>
  );
};

export default Parametre;