import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import axios from 'axios';
import Header from "../../components/Header";
import { Profil } from "../../data/services";


const Form = () => {
  const [profil, setprofil] = useState([])

  useEffect(() => {
    Profil(setprofil)
    const interval = setInterval(() => {
        Profil(setprofil)
    }, 600000);
    return () => {
        clearInterval(interval)
    }
}, [])

  return (
    <div class="row  container-fluid p-0 mx-5">
      <div class="col fs-1">
        <div class="container mt-4 py-5">
          <Header title="Profil" subtitle="Profil actuel" />
          <div class="row">
            <div class="card  col-md-sm p-0 col-lg-auto ms-1 me-1">
              <div class="card-body" style={{width:"250px"}}>
                <div class="d-flex flex-column mt-5 align-items-center text-center">
                    <img src="../../assets/user.png" alt="bg" className="m-2" style={{ width: "100px", height: "100px" }}/>
                  </div>
                  <div class="d-flex flex-column mt-5 align-items-center text-center">
                    <img src="../../assets/logonewj.png" alt="bg" className="m-2" style={{ width: "80px", height: "80px" }}/>
                  </div>
                  <div class=" fs-4 fw-bold d-flex flex-column  align-items-center text-center">
                    Newjoule
                  </div>
              </div>
            </div>
            <div class="col-sm col-md-sm  ms-1 me-1 card mt-3 mt-lg-0 ">
              <div class="row mt-3 mb-2">
                <label for="colFormLabelLg" class="col-sm-2 ms-2 col-form-label col-form-label-lg fs-6 d-flex">Nom</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control " id="colFormLabelLg" placeholder={profil?.nom}/>
                </div>
              </div>
              <div class="row mt-2 mb-2">
                <label for="colFormLabelLg" class="col-sm-2 ms-2 col-form-label col-form-label-lg fs-6 d-flex">Email</label>
                <div class="col-sm-9">
                  <input type="email" class="form-control " id="colFormLabelLg" placeholder={profil?.email}/>
                </div>
              </div>
              <div class="row mt-2 mb-2">
                <label for="colFormLabelLg" class="col-sm-2 ms-2 col-form-label col-form-label-lg fs-6 d-flex">Tel</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control " id="colFormLabelLg" placeholder="+21206........." />
                </div>
              </div>
              <div class="row mt-2 mb-2">
                <label for="colFormLabelLg" class="col-sm-2 ms-2 col-form-label col-form-label-lg fs-6 d-flex">Addresse</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control " id="colFormLabelLg" placeholder="Casablanca"/>
                </div>
              </div>
              <div class="row mt-2 mb-2">
                <label for="colFormLabelLg" class="col-sm-2 ms-2 col-form-label col-form-label-lg fs-6 d-flex">Creé le</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control " id="colFormLabelLg" placeholder={`${profil?.createdAt?.slice(0,10)} à ${profil?.createdAt?.slice(11,13)}h:${profil?.createdAt?.slice(14,19)}`}/>
                </div>
              </div>

              <div class="row mt-2 mb-2">
                <label for="colFormLabelLg" class="col-sm-2 ms-2 col-form-label col-form-label-lg fs-6 d-flex">Dernière mis a jour</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control " id="colFormLabelLg" placeholder={`${profil?.updatedAt?.slice(0,10)} à ${profil?.updatedAt?.slice(11,13)}h:${profil?.updatedAt?.slice(14,19)}`}/>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
