import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import Chart from "react-apexcharts";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";

const ChartCosPhi = ({ cosPhi1, cosPhi2,cosPhi3,couleur, width, height }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setData({ cosPhi1, cosPhi2,cosPhi3 });
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <div style={{ width: width, height: height }}>
      {isLoading ? 
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center",   height: "100%",   width: "100%"}}>
          <CircularProgress />
        </div> : 
        <Chart
          options={{
            chart: {
              type: "polarArea",
            },
            colors: ['#fc0362', '#07922a', '#0398fc', '#8929c4', '#74EFF7', '#FA559F'],
            legend: {
              position: "bottom",
              offsetY: -20, 
              labels: {
                colors: [couleur]
              }
            },
            labels: ["Pf1", "Pf2", "Pf3"],
            stroke: {colors: ["#fff"]},
            fill: {opacity: 0.9},
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: { width: 200},
                  legend: {position: "bottom"},
                },
              },
            ],
          }}
          series={[
            Number(cosPhi1 * 100).toFixed(2),
            Number(cosPhi2 * 100).toFixed(2),
            Number(cosPhi3 * 100).toFixed(2),
          ]}
          type="polarArea"
          width="100%"
          height="100%"
          //width="300px"
        />
      }
    </div>
  );
};

export default ChartCosPhi;
