import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import Chart from "react-apexcharts";
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect ,useState } from "react";


const ChartBarArea = ({serie,categorie,type,grid,unit,width,height,couleur}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data,setData] = useState({})
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setData({serie});
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <div style={{width:width,height:height}}>
      { isLoading ? 
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100%" ,width:"100%"}}>
          <CircularProgress />
        </div>:
    
        <Chart class='ms-3  py-1'
            options={{
                chart: {
                    type: type},
                colors: type==='scatter' ? ["#000"]: ['#fc0362', '#07922a', '#0398fc', '#8929c4', '#74EFF7', '#FA559F'],
                dataLabels: { enabled: false},
                stroke: {curve: 'smooth',width: 2},
                legend: {
                  position: 'bottom',
                  horizontalAlign: 'center',
                  offsetY: 10, 
                  labels: {
                    colors: [couleur]
                  }
                  },
                grid: {
                  show: grid,
                },
                xaxis: {
                    categories: categorie,
                    type: 'datetime',
                    labels: {show: true,
                      style: {
                        colors: couleur,
                      }},
                      axisBorder: {
                        show: true
                      },
                      
                },
                yaxis: {
                    labels: {formatter: function (val) {return parseFloat(val).toFixed(2)},
                    style: {
                      colors: couleur,
                    }},
                    axisBorder: {
                      show: true
                    },
                    
                },
                tooltip: {
                  theme: 'dark',
                    y: {
                        formatter: function (val) {
                            return val + unit
                        }
                    },
                    x: {
                        format: "dd.MM.yyyy HH:mm"
                    },
                    
                },
            }}
        series={serie}
        type={type}
        width="95%"
        height="97%"
        />
        }
        
    </div>
  );
};

export default ChartBarArea;
