import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import axios from 'axios';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';
import { login } from './routes';



const Login = () => {
    const [credentials, setCredentials] = useState({ email: "", password: "" })
    const [erreur, seterreur] = useState("")
    const [voirpass, setvoirpass] = useState(false)
    const navigate = useNavigate();


    const handlchange = ({ currentTarget }) => {
        console.log(currentTarget)
        const { value, name } = currentTarget
        setCredentials({ ...credentials, [name]: value })
    }

    const handlsubmite = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(login, credentials);
            const data = response.data;
            //console.log(data);
            window.localStorage.setItem('user', data.user.email);
            window.localStorage.setItem('token', data.token);
            axios.defaults.headers["x-access-token"] = data.token;
            navigate('/');
        } catch (error) {
            seterreur("User ou password invalide *")
            console.log(error)
        }
    }


    useEffect(() => {
        seterreur("")
        const interval = setInterval(() => {
            seterreur("")
        }, 7000);
        return () => {
            clearInterval(interval)
        }
    }, [])




    return (
        <div class='container '>
            <div class='col  d-flex align-items-center justify-content-center mt-5 text-center' >
                <form onSubmit={handlsubmite} >
                    <img src="assets/login1.png" alt="bg" fluid  style={{width:"450px",height:"450px"}}/>
                    <div className='mb-4 mt-4'>
                        <TextField name="email" id="email" label="User" variant="standard" fullWidth onChange={handlchange} />
                    </div>
                    <div className='mt-2'>
                        <span className='d-flex align-items-center  justify-content-center'>
                            <TextField name="password"
                                id="exampleInputPassword1"
                                label="Password"
                                variant={"standard"}
                                type={voirpass ? 'text' : 'password'}
                                autoComplete="current-password"
                                fullWidth onChange={handlchange} />
                            <span onClick={() => { setvoirpass(!voirpass) }} >{voirpass ? <VisibilityIcon /> : <VisibilityOffIcon />} </span>
                        </span>

                    </div>
                    <div className='container text-center mt-4 text-danger'>{erreur === "" ? "" : <Alert severity="error">{erreur}</Alert>}</div>
                        <Button variant="contained" color='primary' endIcon={<LoginIcon />} type="submit" fullWidth className='mt-3'>
                            login
                        </Button>
                </form>

            </div>
            <div className=' d-flex align-items-center justify-content-center mt-5 mb-auto'>
            <span className='mt-5'><img className='mx-3' src="assets/logonewj.png" alt="bg" fluid  style={{width:"65px",height:"65px"}}/> <img src="assets/user.png" alt="bg" fluid style={{width:"60px",height:"60px"}} /></span>
            </div>
        </div>
    );
}

export default Login;
/*
Login.propTypes = {
    setToken: PropTypes.func.isRequired
}
*/















/*
import { Box } from "@mui/material";


const Login = () => {
    return (
      <Box m="20px">
        login
      </Box>
    );
  };
  
  export default Login;
*/


