import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import Chart from "react-apexcharts";
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect ,useState } from "react";


const ChartTranche = ({serie,categorie,couleur,grid,unit,width,height}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data,setData] = useState({})
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setData({serie});
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <div style={{width:width,height:height}}>
      { isLoading ? 
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100%" ,width:"100%"}}>
          <CircularProgress />
        </div>:
    
        <Chart
        options={{
        chart: {
            type: "bar",
            height: 350,
        },
        colors: ['#fc0362', '#07922a', '#0398fc', '#8929c4', '#74EFF7', '#FA559F'],
        grid: {
            show: grid
          },
        plotOptions: {
            bar: {
            horizontal: true,
            },
        },
        legend: {
            labels: {
              colors: [couleur]
            }
            },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
            return val + unit;
            },
        },
        xaxis: {
            categories: categorie,
            labels: {
            format: "MMM/yyy",
            style: {
                colors: couleur,
              }
            },
        },
        yaxis: {
            //reversed: true,
            axisTicks: {
            show: true,
            } ,
            labels: { 
            style: {
              colors: couleur,
            }}
        },
        tooltip: {theme: 'dark', },
        }}
        series={serie}
        type="bar"
        width="100%"
        height="100%"
        />
        }
        
    </div>
  );
};

export default ChartTranche;

