import Header from "./Header";
import axios from 'axios';
import {
    getAllSerial,
    getAllTopics,
    getAllTopicmap,
    getLastData,
    getRangeData,
    getRangeDataByTranch,
    getDayNightDataByRange,
    getEnergyHeurePleineCreusePointe,
    profil,
    deleteUserByEmail,
    deleteTopicByName,
    deleteTopicmapByName,
    deleteSerialByName,
    getAlert

} from "../routes"


export const GetLastData = (data,serial) =>{
    axios.get(`${getLastData}${serial}`, Header()).then((response) => {
        data(response?.data)
        //console.log("GetLastData",response?.data)
    });
}

export const GetRangeData = (data,serial,starDate,endDate,unit) =>{
    axios.get(`${getRangeData}${serial}/${starDate}/${endDate}/${unit}`, Header()).then((response) => {
        data(response?.data)
        //console.log("GetRangeData",response?.data)
    });
}

export const GetRangeDataByTranch = (data,serie,starDate,endDate,dayWeekMonth,unit) =>{
    axios.post(`${getRangeDataByTranch}${starDate}/${endDate}/${dayWeekMonth}/${unit}`, {"series":serie },Header()).then((response) => {
        data(response?.data[0])
        //console.log("GetRangeDataByTranch",response?.data[0])
    });
}


export const GetDayNightDataByRange = (data,serial,starDate,endDate) =>{
    axios.get(`${getDayNightDataByRange}${serial}/${starDate}/${endDate}`, Header()).then((response) => {
        data(response?.data.Total_positive_active_energy)
        //console.log("GetDayNightDataByRange",response.data.Total_positive_active_energy)
    });
}

export const GetEnergyHeurePleineCreusePointe = (data,serie,starDate,endDate) =>{
    axios.post(`${getEnergyHeurePleineCreusePointe}${starDate}/${endDate}`, {"series":serie },Header()).then((response) => {
        data(response?.data.results.Total_positive_active_energy)
        //console.log("GetEnergyHeurePleineCreusePointe",response?.data.results.Total_positive_active_energy)
    });
}

export const GetSerial = (series) =>{
    axios.get(`${getAllSerial}`, Header()).then((response) => {
        const serials = [];
        response.data?.forEach((item) => {
            serials.push(item?.name)
        })
        series(serials)
        //console.log("serial",serials)
    });
}
export const GetTopic = (topics) =>{
    axios.get(`${getAllTopics}`, Header()).then((response) => {
        const topic = [];
        response.data?.forEach((item) => {
            topic.push(item?.name)
        })
        topics(topic)
        //console.log("topic",topic)
    });
}

export const GetTopicMap = (topicmaps) =>{
    axios.get(`${getAllTopicmap}`, Header()).then((response) => {
        const topicmap = [];
        response.data?.forEach((item) => {
            topicmap.push(item?.name)
        })
        topicmaps(topicmap)
        //console.log("topicmap",topicmap)
    });
}

export const Profil = (profil_) =>{
    axios.get(`${profil}/${window.localStorage.getItem('user')}`, Header()).then((response) => {
        //console.log(response?.data)
        profil_(response?.data)
    });
}

export const DeleteUser = (resultat,email) =>{
        axios.delete(`${deleteUserByEmail}/${email}`, Header()).then((response) => {
            //console.log("---",response?.data)
            resultat(response?.data)
        })
     .catch(error=>{
        if (error.response.status === 404) {
            resultat("L'utilisateur n'existe pas");
          } else {
            resultat("Erreur serveur");
          }
    });
}


export const DeleteTopic = (resultat,topic) =>{
    axios.delete(`${deleteTopicByName}/${encodeURIComponent(topic)}`, Header()).then((response) => {
        //console.log("---",response?.data)
        resultat(response?.data)
    })
 .catch(error=>{
    if (error.response.status === 404) {
        resultat("Aucun topic correspondant au nom fourni n'a été trouvé");
      } else {
        resultat("Erreur serveur");
      }
});
}

export const DeleteTopicmap = (resultat,topicmap) =>{
    axios.delete(`${deleteTopicmapByName}/${encodeURIComponent(topicmap)}`, Header()).then((response) => {
        //console.log("---",response?.data)
        resultat(response?.data)
    })
 .catch(error=>{
    if (error.response.status === 404) {
        resultat("Aucun topicmap correspondant au nom fourni n'a été trouvé");
      } else {
        resultat("Erreur serveur");
      }
});
}

export const DeleteSerial = (resultat,serial) =>{
    axios.delete(`${deleteSerialByName}/${encodeURIComponent(serial)}`, Header()).then((response) => {
        //console.log("---",response?.data)
        resultat(response?.data)
    })
 .catch(error=>{
    if (error.response.status === 404) {
        resultat("Aucun serial correspondant au nom fourni n'a été trouvé");
      } else {
        resultat("Erreur serveur");
      }
});
}

export const GetAlert = (data) =>{
    axios.get(`${getAlert}`, Header()).then((response) => {
        data(response?.data)
        //console.log("getAlert",response?.data)
    });
}