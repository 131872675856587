import { useState,useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import { GetTopicMap } from "../../data/services"; 

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem active={selected === title} style={{ color: colors.grey[100],}} onClick={() => setSelected(title)} icon={icon}>
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [topicMaps,setTopicMaps] = useState([]);

  useEffect(() => {
    GetTopicMap(setTopicMaps)
    const interval = setInterval(() => {
      GetTopicMap(setTopicMaps)
    }, 600000); //10min 
    return () => {
      clearInterval(interval);
    };
  }, []);

  const logout = () => {
    window.localStorage.setItem('user', "");
    window.localStorage.setItem('token', "");
}
  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {background: `${colors.primary[400]} !important`, },
        "& .pro-icon-wrapper": {backgroundColor: "transparent !important",},
        "& .pro-inner-item": {padding: "5px 35px 5px 20px !important",},
        "& .pro-inner-item:hover": {color: "#868dfb !important",},
        "& .pro-menu-item.active": {color: "#6870fa !important",},
      }}  >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem onClick={() => setIsCollapsed(!isCollapsed)} icon={isCollapsed ? <MenuOutlinedIcon /> : undefined} style={{ margin: "10px 0 20px 0", color: colors.grey[100], }} >
            {!isCollapsed && (
              <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px" >
                <Typography variant="h4" color={colors.grey[100]}>
                <Box className='d-flex align-items-center py-1'>
                  <img src="../../assets/logonewj.png" alt="bg" className='me-2' style={{ width: '35px', height: '35px' }} />
                  <span className={`fw-bold fs-5 ${theme.palette.mode === "dark"?"text-white":"text-secondary"}`}  >API NewJoule</span>
                </Box>
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img alt="profile-user" width="85px" height="100px" src={`../../assets/user.png`} style={{ cursor: "pointer", borderRadius: "50%" }} />
              </Box>
              <Box textAlign="center">
                <Typography variant="h2" color={colors.grey[100]} fontWeight="bold" sx={{ m: "10px 0 0 0" }} >
                  Labomag
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  User Admin
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item title="Dasborard DirisA20" to="/" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} />
            <Item title="Dashboard" to="/dashboard" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} />

            <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }} > Charts </Typography>
            { topicMaps.map((item) => {
              return (<Item key={item} title={item} to={`/` + item} icon={<AdUnitsIcon />} selected={selected} setSelected={setSelected}/>);
            })
            }

            <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }} > Pages </Typography>
            <Item title="Profil" to="/form" icon={<PersonOutlinedIcon />} selected={selected} setSelected={setSelected} />
            <Item title="Alerte" to="/alerte" icon={ <NotificationsOutlinedIcon/>} selected={selected} setSelected={setSelected} />
            <Item title="Paramètre" to="/parametre" icon={ <SettingsSuggestOutlinedIcon/>} selected={selected} setSelected={setSelected} />
            <Item title="Calendar" to="/calendar" icon={<CalendarTodayOutlinedIcon />} selected={selected} setSelected={setSelected} />
            <Item title="FAQ Page" to="/faq" icon={<HelpOutlineOutlinedIcon />} selected={selected} setSelected={setSelected} />

            <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }} > Logout </Typography>
            <Item onClick={()=> logout()} title="Déconnecter" to="/login" icon={<LogoutIcon />} selected={selected} setSelected={setSelected} />
            
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;