import { useState,useEffect } from "react";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import DasborardDirisA20 from "./scenes/dasborardDirisA20";
import COMP_SCHNEIDER from "./scenes/compteurSchneider"
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import Calendar from "./scenes/calendar"
import Login from "./login"
import { GetTopicMap,GetSerial } from "./data/services";
import Parametre from "./scenes/parametre";
import Alerte from "./scenes/alerte";

function App() {
  const [theme, colorMode] = useMode();
  const location = useLocation();

  const [topicMaps,setTopicMaps] = useState([]);
  const [serial,setserial] = useState([]);

  useEffect(() => {
    GetTopicMap(setTopicMaps)
    GetSerial(setserial)
    const interval = setInterval(() => {
      GetTopicMap(setTopicMaps)
      GetSerial(setserial)
    }, 600000); //10min 
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider  theme={theme}>
        <CssBaseline />
        <div className="app">
          {location.pathname !== "/login" && <Sidebar />}
          <main className="content">
            {location.pathname !== "/login" && <Topbar/>}
            
            
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route element={<PrivateRoute/>}>
              <Route path="/" element={<DasborardDirisA20 />} />
                <Route path="/dashboard" element={<Dashboard />} />
                { topicMaps.map((item,index) => {return (<Route key={item} path={`/` + item} element={<COMP_SCHNEIDER topicMap={item} serial={serial[index]} />} />)})}
                <Route path="/form" element={<Form />} />
                <Route path="/alerte" element={<Alerte/>}></Route>
                <Route path="/parametre" element={<Parametre/> } />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/faq" element={<FAQ />} />
               
              </Route>
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;






/*
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import Calendar from "./scenes/calendar"
import Login from "./login"


function App() {
  const [theme, colorMode] = useMode();

  
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar/>
          <main className="content">
            <Topbar />
            <Routes>
            
              <Route path="/" element={<Dashboard />} />
              <Route path="/login" element={<Login />} />
               <Route path="/team" element={<Team />} /> 
               <Route path="/contacts" element={<Contacts />} /> 
               <Route path="/invoices" element={<Invoices />} /> 
               <Route path="/form" element={<Form />} />
               <Route path="/calendar" element={<Calendar />} />
               <Route path="/faq" element={<FAQ />} />
               <Route path="/bar" element={<Bar />} />
               <Route path="/pie" element={<Pie />} />
               <Route path="/line" element={<Line />} />
              <Route path="/geography" element={<Geography />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
*/